import { Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { CoreMetrics } from "../firebase/converters/analysisSet";
import { Metric } from "../model/metrics";

export const TD_HQ_USER_ID = "quZSnZ8lKLSVEeBh8A9EpfL8cjv1";
export const TD_HQ_GEO = {
  lat: 38.9850629,
  lng: -94.71204,
};
export const FILTER_THROW_LIMIT_DEFAULT = 250;

export const METRICS_INFO = [
  {
    metric: Metric.Speed,
    label: t("metrics.speed.full"),
    accessor: (metric: CoreMetrics) => metric.speedMph,
  },
  {
    metric: Metric.Spin,
    label: t("metrics.spin.full"),
    accessor: (metric: CoreMetrics) => metric.spinRpm,
  },
  {
    metric: Metric["Off Axis Torque"],
    label: t("metrics.wobble.full"),
    accessor: (metric: CoreMetrics) => metric.launchAngle,
  },
  {
    metric: Metric.Hyzer,
    label: t("metrics.hyzer.full"),
    accessor: (metric: CoreMetrics) => metric.hyzerAngle,
  },
  {
    metric: Metric["Nose Angle"],
    label: t("metrics.nose.full"),
    accessor: (metric: CoreMetrics) => metric.noseAngle,
  },
  {
    metric: Metric["Uphill Angle"],
    label: t("metrics.launch.full"),
    accessor: (metric: CoreMetrics) => metric.launchAngle,
  },
];

/**
 * Represents the warning status of a throw.
 */
export enum ThrowWarningStatus {
  CLOSE_NET = "CLOSE_NET",
  DEVICE_DISABLED = "DEVICE_DISABLED",
  RAPID_FIRE = "RAPID_FIRE",
  ACCEL2_ERROR = "ACCEL2_ERROR",
  LOW_SPEED = "LOW_SPEED",
}

/**
 * Represents a warning associated with a throw.
 */
export interface ThrowWarning {
  severity: ThrowWarningSeverity;
  title?: string;
  message?: string | React.ReactNode;
}

/**
 * Represents the severity of a throw warning.
 */
export enum ThrowWarningSeverity {
  INFO,
  WARNING,
  CRITICAL,
}
export const THROW_WARNINGS: Record<ThrowWarningStatus, ThrowWarning> = {
  [ThrowWarningStatus.LOW_SPEED]: {
    severity: ThrowWarningSeverity.INFO,
    title: "Low Speed",
    message: "Low Speed",
  },
  [ThrowWarningStatus.ACCEL2_ERROR]: {
    severity: ThrowWarningSeverity.CRITICAL,
    message:
      "A sensor within your device has failed, which may be impacting data quality. Please contact support via help@techdisc.com.",
    title: "Sensor Error",
  },
  [ThrowWarningStatus.DEVICE_DISABLED]: {
    severity: ThrowWarningSeverity.CRITICAL,
    title: "Device Disabled",
    message:
      "This device has been reported as lost or stolen by its owner. Please contact support via help@techdisc.com.",
  },
  [ThrowWarningStatus.CLOSE_NET]: {
    severity: ThrowWarningSeverity.WARNING,
    title: "Close Net",
    message:
      "Proximity to net may have impacted data accuracy, for best results increase spacing to net.",
  },
  [ThrowWarningStatus.RAPID_FIRE]: {
    severity: ThrowWarningSeverity.WARNING,
    title: "Rapid Fire",
    message: "For best results, wait a second or two longer between throws.",
  },
};
