import { Button, Paper, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  ContentCopy,
  Dataset,
  PermIdentity,
  Person,
  Refresh,
  SportsHandball,
  SquareFoot,
  SvgIconComponent,
  TrendingUpOutlined,
} from "@mui/icons-material";

import { useParams } from "react-router-dom";
import { useUserMetadata } from "../../hooks/useUserMetadata";
import { useGlobal } from "../providers/GlobalProvider";
import { Strong } from "../Text";
import copy from "copy-to-clipboard";
import { toast } from "sonner";

export const InspectHeader = ({ userId }: { userId: string }) => {
  const [userMetadata] = useUserMetadata(userId);
  const { userSettings, featureFlags } = useGlobal();
  const { inspectView, inspectId } = useParams();
  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent={"space-between"} width="100%" gap={1}>
      <Paper
        variant="outlined"
        color="primary"
        elevation={1}
        component={Stack}
        direction="row"
        justifyContent={"space-between"}
        sx={{
          color: (theme) => theme.palette.secondary.dark,
          width: "100%",
          px: 2,
          py: 1.5,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: "100%", justifyContent: "center", gap: 4 }}
        >
          {inspectId && (
            <CopyText
              type={inspectView === "throws" ? "Throw ID" : "Set ID"}
              id="inspect-id"
              Icon={inspectView === "throws" ? Dataset : TrendingUpOutlined}
            >
              {inspectId}
            </CopyText>
          )}
          {userMetadata?.name && (
            <CopyText type="display name" id="display-name" Icon={Person}>
              {userMetadata?.name}
            </CopyText>
          )}
          <CopyText type="ID" id="user-id" Icon={PermIdentity}>
            {userId}
          </CopyText>
          {userSettings?.handedness && (
            <CopyText type="handedness" id="handedness" Icon={SportsHandball}>
              {userSettings?.handedness}
            </CopyText>
          )}
          {featureFlags.metric_enabled && userSettings?.preferredMeasurementSystem && (
            <CopyText type="measurement system" id="measurement-system" Icon={SquareFoot}>
              {userSettings?.preferredMeasurementSystem === "us" ? "US (ft)" : "Metric (m)"}
            </CopyText>
          )}
        </Stack>
        <Button size="small" variant="outlined" onClick={() => navigate("/admin/inspect")}>
          <Refresh fontSize="small" />
          Reset
        </Button>
      </Paper>
    </Stack>
  );
};

const CopyText = ({
  id,
  type,
  children,
  Icon,
}: {
  id: string;
  type: string;
  children: string;
  Icon: SvgIconComponent;
}) => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        userSelect: "none",
        color: "rgba(0, 0, 0, 0)",
        [`& div#${id}`]: { color: "secondary.light" },
        "&:hover": {
          color: "primary.main",
          [`& div#${id}`]: { color: "primary.main" },
          cursor: "pointer",
        },
      }}
    >
      <Stack
        direction="row"
        gap={0.5}
        id={id}
        onClick={() => {
          const directCopy = copy(children, { message: "Select all and copy text:" });

          if (directCopy) {
            toast.info(`Copied user ${type} to clipboard.`);
          }
        }}
      >
        <Icon fontSize="small" />
        <Strong color="auto">{children}</Strong>
      </Stack>
      <ContentCopy fontSize="small" />
    </Stack>
  );
};
