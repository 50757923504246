import { VideoCameraFront } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

export type SelectVideoDeviceProps = {
  devices: MediaDeviceInfo[];
  selectedDevice?: MediaDeviceInfo | null;
  onDeviceChange: (device: MediaDeviceInfo) => void;
};

export const SelectVideoDevice = (props: SelectVideoDeviceProps) => {
  const { devices, onDeviceChange, selectedDevice } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (devices.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (devices.length === 0) return;
    setAnchorEl(null);
    onDeviceChange(devices[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        component="button"
        id="lock-button"
        aria-haspopup="listbox"
        aria-controls="lock-menu"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickListItem}
        gap={1}
        disabled={devices.length === 0}
      >
        <VideoCameraFront fontSize="large" />
        {selectedDevice ? (
          <Box sx={{ fontSize: "1.4rem", fontWeight: 500 }}>{selectedDevice?.label}</Box>
        ) : (
          <Box sx={{ fontSize: "1rem", fontWeight: 300 }}>Select a recording device</Box>
        )}
      </Stack>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          fontSize: "1.2rem",
          fontWeight: 400,
        }}
      >
        {devices.map((device, index) => (
          <MenuItem
            key={device?.deviceId}
            selected={device?.deviceId === selectedDevice?.deviceId}
            onClick={(event) => {
              handleMenuItemClick(event, index);
            }}
          >
            {device?.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
