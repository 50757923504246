import { Timestamp, limit, orderBy, query, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Subject, distinctUntilChanged, filter, map } from "rxjs";
import { getUserThrowSummaries } from "../firebase/collections";
import { ThrowSummary } from "../model/throwSummary";
import { formatThrowSummary } from "../utils/throw";

interface RecentThrowsHookOptions {
  userId: string;
  startTime?: Timestamp;
  skip?: boolean;
  maxThrows?: number;
}

const throwSubject$ = new Subject<ThrowSummary[]>();

export const useRecentThrows = ({
  userId,
  startTime,
  maxThrows,
  skip,
}: RecentThrowsHookOptions) => {
  const [allThrows, setAllThrows] = useState<(ThrowSummary | undefined)[]>([]);
  const [latestThrow, setLatestThrow] = useState<ThrowSummary | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const queryConstraints = useMemo(() => {
    const constraints = [];
    if (startTime) {
      constraints.push(where("throwTime", ">", startTime?.toDate()));
    }
    constraints.push(orderBy("throwTime", "desc"));
    constraints.push(limit(maxThrows ?? 10));
    return constraints;
  }, [maxThrows, startTime]);

  const [throws] = useCollectionData(
    skip ? null : query(getUserThrowSummaries(userId), ...queryConstraints),
    {
      initialValue: [],
    },
  );

  useEffect(() => {
    if (throws && throws.length > 0) {
      throwSubject$.next(throws);
    }
  }, [throws]);

  useEffect(() => {
    if (startTime) {
      throwSubject$.next([]);
    }
  }, [startTime]);

  useEffect(() => {
    const allThrowsTopic = throwSubject$
      .pipe(
        filter((throws) => throws.every((summary) => !summary.loading)),
        map((throws) => throws.map((summary) => formatThrowSummary(summary))),
        distinctUntilChanged((prev, curr) =>
          prev.every((prevThrow, index) => prevThrow?.id === curr[index]?.id),
        ),
      )
      .subscribe((incomingThrows) => {
        setAllThrows(incomingThrows);
      });

    const latestTopic = throwSubject$
      .pipe(
        filter((throws) => throws.every((summary) => !summary?.loading)),
        map((throws) => formatThrowSummary(throws[0])),
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
      )
      .subscribe((latestThrow) => {
        console.log(latestThrow);

        setLatestThrow(latestThrow);
      });

    return () => {
      allThrowsTopic.unsubscribe();
      latestTopic.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loadingTopic = throwSubject$
      .pipe(
        map((summaries) => summaries.some((summary) => summary.loading)),
        distinctUntilChanged(),
      )
      .subscribe((loading) => {
        setIsLoading(loading);
      });
    return () => {
      loadingTopic.unsubscribe();
    };
  }, []);

  return { allThrows, latestThrow, loading: isLoading, reset: () => throwSubject$.next([]) };
};
