import { useGlobal } from "@/components/providers/GlobalProvider";
import type { PlayEvent } from "@/types/events";
import { ArrowRight } from "@mui/icons-material";
import { AppBar, Box, Button, Stack, Toolbar } from "@mui/material";
import { format, isAfter, isBefore } from "date-fns";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const PlayEventBanner = ({ playEvents }: { playEvents?: PlayEvent[] }) => {
  const currentDate = new Date();
  const currentEvent = playEvents?.find(
    (event) =>
      event?.previewDate &&
      isBefore(currentDate, event?.endDate?.toDate()) &&
      isAfter(currentDate, event?.previewDate?.toDate()),
  );
  const { featureFlags } = useGlobal();

  const { eventName, startDate, endDate, branding = {} } = currentEvent ?? {};
  const { primaryColor, backgroundColor } = branding;
  const navigate = useNavigate();

  return currentEvent ? (
    <AppBar
      component={motion.nav}
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      position="static"
      sx={{ backgroundColor: backgroundColor ?? "primary.dark" }}
    >
      <Toolbar
        sx={{
          flexDirection: { mobile: "column", tablet: "row" },
          justifyContent: { tablet: "space-between" },
          py: 1,
          gap: 0.5,
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Stack direction="row" justifyContent={{ mobile: "center", tablet: "flex-start" }}>
            {isAfter(currentDate, startDate?.toDate()) ? (
              <Box
                sx={{
                  color: primaryColor ?? "primary.main",
                  fontSize: "20px",
                  letterSpacing: "-1px",
                  fontWeight: 600,
                }}
              >
                LIVE
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  color: primaryColor ?? "primary.main",
                  letterSpacing: "0px",
                  fontWeight: 500,
                  fontSize: "1rem",
                }}
              >
                {format(startDate.toDate(), "MMM d") === format(endDate.toDate(), "MMM d")
                  ? format(startDate.toDate(), "LLLL do")
                  : `${format(startDate.toDate(), "LLLL do")} - ${format(endDate.toDate(), "do")}`}
              </Box>
            )}
          </Stack>
          <Stack
            sx={{
              textAlign: { mobile: "center", tablet: "left" },
              justifyContent: { mobile: "center", tablet: "flex-start" },
              fontSize: "1.4rem",
              width: "100%",
              color: "white",
              textWrap: "no-wrap",
              userSelect: "none",
            }}
          >
            {eventName}
          </Stack>
        </Stack>
        {currentEvent.isLive && (
          <Stack direction="row" gap={1}>
            <Button
              variant="menu"
              onClick={() => navigate(`/play/leaderboards/event/${currentEvent.id}`)}
            >
              LEADERBOARDS
            </Button>
            {featureFlags?.stroke_play && (
              <Button variant="menu" onClick={() => navigate(`/play/stroke`)}>
                PLAY <ArrowRight />
              </Button>
            )}
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  ) : null;
};
