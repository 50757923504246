import {
  getLayoutLeaderboardCollection,
  getPlayEventLeaderboardCollection,
} from "@/firebase/collections";
import type { StrokePlayLeaderboardEntry } from "@/model/leaderboard";
import { PlayEvent } from "@/types/events";
import { addDays, differenceInCalendarDays, format } from "date-fns/esm";
import { onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";

interface EventLeaderboardsResponse {
  layout: StrokePlayLeaderboardEntry[];
  daily: StrokePlayLeaderboardEntry[][];
}
export const useEventLeaderboard = (event?: PlayEvent): [EventLeaderboardsResponse, boolean] => {
  const [loading, setLoading] = useState(true);
  const eventDates = event
    ? Array.from(
        {
          length:
            differenceInCalendarDays(event?.endDate?.toDate(), event?.startDate?.toDate()) + 1,
        },
        (_, i) => format(addDays(event?.startDate?.toDate(), i), "yyyy-MM-dd"),
      )
    : [];

  const [leaderboards, setLeaderboards] = useState<EventLeaderboardsResponse>({
    layout: [],
    daily: [],
  });

  const fetchLeaderboards = (layoutIds: string[]) => {
    if (!event) return;

    try {
      setLoading(true);
      layoutIds.map((layoutId) => {
        const eventQs = eventDates.map((date) => {
          return query(
            getPlayEventLeaderboardCollection(`${layoutId}-${date}`),
            orderBy("totalStrokes", "asc"),
          );
        });
        const layoutQ = query(
          getLayoutLeaderboardCollection(layoutId),
          orderBy("totalStrokes", "asc"),
        );
        const dailyUnsubs = eventQs.map((eventQ) =>
          onSnapshot(eventQ, (dailyBoardSnapshot) => {
            const entries = dailyBoardSnapshot.docs
              .map((doc) => doc.data() as StrokePlayLeaderboardEntry)
              .filter((entry) => !entry.isHidden);

            setLeaderboards((leaderboards) => ({
              ...leaderboards,
              daily: [...leaderboards.daily, entries],
            }));
          }),
        );
        const unsubscribeLayouts = onSnapshot(layoutQ, (layoutBoardSnapshot) => {
          const entries = layoutBoardSnapshot.docs
            .map((doc) => doc.data() as StrokePlayLeaderboardEntry)
            .filter((entry) => !entry.isHidden);
          setLeaderboards((leaderboards) => ({
            ...leaderboards,
            layout: entries,
          }));
        });
        return () => {
          unsubscribeLayouts();
          dailyUnsubs.forEach((unsub) => unsub());
        };
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (event && event.layoutIds) {
      return fetchLeaderboards(event.layoutIds);
    }
  }, [event]);

  return [leaderboards, loading];
};
