import { videoClient } from "@/components/routes/video/client";
import { devicesChanged$, getDevices, requestRecordingPermission } from "@/utils/video";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useBeforeUnload } from "react-router-dom";

export type VideoDeviceData = {
  id: string;
  info?: MediaDeviceInfo;
  stream?: MediaStream;
};

export type VideoDeviceContextType = {
  devices: MediaDeviceInfo[];
  activeDevice: VideoDeviceData | undefined;
  addDevice: (device: VideoDeviceData) => void;
  removeDevice: () => void;
  getDevice: () => VideoDeviceData | undefined;
  getVideoSettings: () => MediaTrackSettings | undefined;
  handleStartRecording: (deviceId: string) => void;
  handleStopRecording: () => void;
  handleActiveDeviceChange: (device: VideoDeviceData) => void;
  handleConnect: (deviceId: string) => Promise<VideoDeviceData | undefined>;
};

export const VideoDeviceContext = createContext<VideoDeviceContextType>({
  devices: [],
  activeDevice: undefined,
  addDevice: () => {},
  removeDevice: () => {},
  getDevice: () => undefined,
  getVideoSettings: () => undefined,
  handleConnect: () => Promise.resolve(undefined),
  handleStartRecording: () => {},
  handleStopRecording: () => {},
  handleActiveDeviceChange: () => {},
});

export const VideoDeviceProvider = ({ children }: { children: React.ReactNode }) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDevice, setActiveDevice] = useState<VideoDeviceData | undefined>(undefined);

  const handleConnect = useCallback(
    async (deviceId: string) => {
      const result = await videoClient.connect(deviceId);
      if (result) {
        const { stream } = result;
        const device = devices.find((device) => device.deviceId === deviceId);
        await videoClient.startRecording(deviceId);
        return { stream, id: deviceId, info: device };
      }
      return undefined;
    },
    [devices],
  );

  const handleStartRecording = useCallback(
    async (deviceId: string) => {
      try {
        console.log("Starting stream for", deviceId);

        const result = await handleConnect(deviceId);
        if (result) {
          const { stream, info } = result;
          setActiveDevice({ id: deviceId, stream, info });
        }
      } catch (error) {
        console.error("Error starting stream", error);
      }
    },
    [handleConnect, devices],
  );

  const handleStopRecording = useCallback(() => {
    if (!activeDevice) return;

    videoClient.close();
    setActiveDevice(undefined);
  }, [setActiveDevice]);

  // Request devices
  useEffect(() => {
    const exec = async () => {
      await requestRecordingPermission();

      const devices = await getDevices();
      setDevices(devices);

      const deviceSubscription = devicesChanged$.subscribe(async () => {
        setDevices(await getDevices());
      });

      return () => {
        deviceSubscription.unsubscribe();
      };
    };
    exec();
  }, [setDevices]);

  const handleActiveDeviceChange = useCallback(
    (device: VideoDeviceData) => {
      handleStopRecording();
      handleStartRecording(device.id);
      setActiveDevice(device);
    },
    [handleStopRecording, handleStartRecording, setActiveDevice],
  );

  const addDevice = useCallback(
    (device: VideoDeviceData) => {
      setActiveDevice(device);
    },
    [setActiveDevice],
  );

  const removeDevice = useCallback(() => {
    setActiveDevice(undefined);
  }, [setActiveDevice]);

  const getDevice = useCallback(() => {
    return activeDevice;
  }, [activeDevice]);

  const getVideoSettings = useCallback(() => {
    return activeDevice?.stream?.getVideoTracks()[0]?.getSettings();
  }, [activeDevice]);

  // Request devices
  useEffect(() => {
    const exec = async () => {
      await requestRecordingPermission();

      const devices = await getDevices();
      setDevices(devices);

      const deviceSubscription = devicesChanged$.subscribe(async () => {
        setDevices(await getDevices());
      });

      return () => {
        deviceSubscription.unsubscribe();
      };
    };
    exec();
  }, [setDevices]);

  const handleUnload = useCallback(() => {
    handleStopRecording();
  }, [handleStopRecording]);

  useBeforeUnload(handleUnload);
  return (
    <VideoDeviceContext.Provider
      value={{
        devices,
        activeDevice,
        addDevice,
        removeDevice,
        getDevice,
        getVideoSettings,
        handleStartRecording,
        handleStopRecording,
        handleActiveDeviceChange,
        handleConnect,
      }}
    >
      {children}
    </VideoDeviceContext.Provider>
  );
};

export const useVideoDevice = () => {
  const context = useContext(VideoDeviceContext);
  if (!context) {
    throw new Error("useVideoDevice must be used within a VideoDeviceProvider");
  }
  return context;
};
