import { yupResolver } from "@hookform/resolvers/yup";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import "../../../../node_modules/cropperjs/dist/cropper.css";
import React, { useEffect, useRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import { FormProvider, useForm } from "react-hook-form";
import { useStoreMetadata } from "../../../hooks/useStoreMetadata";
import { StoreProfile, storeProfileSchema } from "../../../model/StoreProfile";
import { getUserId, saveLeaderboardStoreMetadata } from "../../../summaryUtils";
import { USER_ROLES } from "../../user/consts";
import { uploadStoreLogo } from "../../user/utils";
import BrandForm from "./BrandForm";
import TestDriveEmailList from "./CustomerList";
import { useGlobal } from "../../providers/GlobalProvider";
import { UserRole } from "../../user/types";
import { useSessionStorage } from "../../../hooks/useLocalStorage";
import StoreInfo from "./StoreInfo";
import { StoreMetadata } from "../../../firebase/converters/leaderboardStoreMetadata";
import { LeaderboardStoreMetadata } from "../../../model/leaderboard";
import { toast } from "sonner";

type StoreManagementTab = {
  LABEL: string;
  ROLE: UserRole;
};

const TABS: Record<Uppercase<string>, StoreManagementTab> = {
  BRANDING: {
    LABEL: "Branding",
    ROLE: USER_ROLES.STORE,
  },
  STORE_INFO: { LABEL: "Store Info", ROLE: USER_ROLES.STORE },
  EMAIL_LIST: { LABEL: "Email List", ROLE: USER_ROLES.PREMIUM_STORE },
};

export function StoreManagement() {
  const userId = getUserId();
  const [storeMetadata, isLoading] = useStoreMetadata(userId);
  const [storeLogo, setStoreLogo] = React.useState<File | null | undefined>(undefined);
  const [currentTab, setCurrentTab] = useSessionStorage(
    "store-mgmt-current-tab",
    Object.values(TABS)[0].LABEL,
  );
  const [imageCropperOpen, setImageCropperOpen] = React.useState(false);
  const [canvasData, setCanvasData] = React.useState<HTMLCanvasElement>();
  const { userRoles } = useGlobal();
  const methods = useForm<StoreProfile>({
    resolver: yupResolver(storeProfileSchema),
    defaultValues: storeMetadata
      ? {
          storeName: storeMetadata.name,
          siteUrl: storeMetadata.website,
          email: storeMetadata.email,
          phone: storeMetadata.phone,
          logoUrl: storeMetadata.logo,
          availability: storeMetadata.availability,
          hideMapLocation: storeMetadata.hideMapLocation,
          address: {
            street: storeMetadata.address?.addressLines?.[0],
            city: storeMetadata.address?.city,
            region: storeMetadata.address?.state,
            country: storeMetadata.address?.country,
            postalCode: storeMetadata.address?.postalCode,
          },
        }
      : {
          // Necessary for the form to initialize, update and reset properly
          storeName: "",
          siteUrl: "",
          email: "",
          phone: "",
          availability: "full",
          hideMapLocation: false,
          logoUrl: "",
          address: {
            street: "",
            city: "",
            region: "",
            country: "",
            postalCode: "",
          },
        },
  });

  const handleClickOpen = () => {
    setImageCropperOpen(true);
  };

  const handleClose = () => {
    setImageCropperOpen(false);
  };

  const cropperRef = useRef<ReactCropperElement>(null);
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    setCanvasData(cropper?.getCroppedCanvas());
  };

  const handleCommitCropData = () => {
    methods.setValue("logoUrl", canvasData?.toDataURL());
    canvasData?.toBlob((blob) =>
      setStoreLogo(new File([blob as any], storeLogo?.name ?? "logo", { type: storeLogo?.type })),
    );
    handleClose();
  };

  const logoUrl = methods.watch("logoUrl");

  const handleDeleteLogo = () => {
    methods.setValue("logoUrl", "", { shouldDirty: true });
    setStoreLogo(null);
  };

  const handleSelectLogo = (logo: File) => {
    methods.setValue("logoUrl", URL.createObjectURL(logo), { shouldDirty: true });
    setStoreLogo(logo);
  };

  const handleReset = React.useCallback(() => {
    if (storeMetadata) {
      methods.reset({
        storeName: storeMetadata.name,
        siteUrl: storeMetadata.website,
        email: storeMetadata.email,
        phone: storeMetadata.phone,
        logoUrl: storeMetadata.logo,
        availability: storeMetadata.availability,
        hideMapLocation: storeMetadata.hideMapLocation,
        address: {
          street: storeMetadata.address?.addressLines?.[0],
          city: storeMetadata.address?.city,
          region: storeMetadata.address?.state,
          country: storeMetadata.address?.country,
          postalCode: storeMetadata.address?.postalCode,
        },
      });
    }
  }, [storeMetadata, methods]);

  const handleSave = React.useCallback(
    async (profileFields: StoreProfile) => {
      try {
        // If there is a profile photo, upload it and get the url
        const logo = storeLogo ? await uploadStoreLogo(storeLogo) : null;
        // Create the user profile
        const updatedStoreMetadata: Partial<StoreMetadata> & {
          address?: Partial<LeaderboardStoreMetadata["address"]>;
        } = {
          name: profileFields.storeName,
          website: profileFields.siteUrl,
          phone: profileFields.phone,
          email: profileFields.email,
          availability: profileFields.availability,
          hideMapLocation: profileFields.hideMapLocation,
          // If there is a photo, use the 512px version
          logo: logo ? logo[512] : profileFields.logoUrl,
          address: {
            addressLines: profileFields.address
              ? (Object.values(profileFields.address).filter((v) => v) as string[])
              : [],
          },
        };

        // Save the user profile
        await saveLeaderboardStoreMetadata(userId, updatedStoreMetadata);

        toast.success("Settings saved successfully.");
      } catch (e) {
        console.error(e);
        toast.error("There was a problem updating your settings, please try again.");
      }
    },
    [storeLogo, userId],
  );

  useEffect(() => {
    if (storeMetadata) {
      methods.reset({
        storeName: storeMetadata.name,
        siteUrl: storeMetadata.website,
        email: storeMetadata.email,
        phone: storeMetadata.phone,
        logoUrl: storeMetadata.logo,
        availability: storeMetadata.availability,
        hideMapLocation: storeMetadata.hideMapLocation,
        address: {
          street: storeMetadata.address?.addressLines?.[0],
          city: storeMetadata.address?.city,
          region: storeMetadata.address?.state,
          country: storeMetadata.address?.country,
          postalCode: storeMetadata.address?.postalCode,
        },
      });
    }
  }, [storeMetadata, methods]);

  const PANELS = {
    [TABS.BRANDING.LABEL]: (
      <BrandForm
        onSelectLogo={(logo) => {
          handleSelectLogo(logo);
          handleClickOpen();
        }}
        onDeleteLogo={handleDeleteLogo}
      />
    ),
    [TABS.STORE_INFO.LABEL]: <StoreInfo storeMetadata={storeMetadata} />,
    [TABS.EMAIL_LIST.LABEL]: <TestDriveEmailList />,
  };
  return (
    <Container
      sx={{
        my: 4,
      }}
    >
      <FormProvider {...methods}>
        <Box
          component={"form"}
          onSubmit={methods.handleSubmit(handleSave)}
          sx={{ display: "flex", flexDirection: "column", gap: 4 }}
        >
          <Typography variant="h5" color="grey.600">
            Store Management
          </Typography>
          <TabContext value={currentTab}>
            {/* Settings Container */}
            <Stack
              sx={{
                bgcolor: "white",
                border: "1px solid",
                borderColor: (theme) => theme.palette.grey[300],
              }}
            >
              {/* Menu & Settings */}
              <Stack direction="row" flexBasis={"100%"} minHeight={{ xl: "60vh" }}>
                {/* Menu */}
                <Stack
                  sx={{
                    borderColor: (theme) => theme.palette.grey[300],
                    bgcolor: (theme) => theme.palette.grey[50],
                    flexBasis: "200px",
                  }}
                >
                  <TabList onChange={(_, tab) => setCurrentTab(tab)} orientation="vertical">
                    {Object.values(TABS).map(({ LABEL, ROLE }) => {
                      return userRoles.includes(ROLE) ? (
                        <Tab
                          label={LABEL}
                          value={LABEL}
                          sx={{ "&:Mui-selected": { color: "white" } }}
                          key={LABEL}
                        />
                      ) : null;
                    })}
                  </TabList>
                </Stack>
                {/* Settings */}
                {!isLoading ? (
                  <Stack flexGrow={3}>
                    {Object.values(TABS).map(({ LABEL, ROLE }) => {
                      return userRoles.includes(ROLE) ? (
                        <TabPanel value={LABEL} key={LABEL}>
                          {PANELS[LABEL]}
                        </TabPanel>
                      ) : null;
                    })}
                  </Stack>
                ) : (
                  <Box sx={{ p: 10, m: "0 auto" }}>
                    <CircularProgress />
                  </Box>
                )}
              </Stack>
              {/* Action Bar */}
              <Stack
                direction={"row"}
                width="100%"
                justifyContent={"flex-end"}
                alignItems={"center"}
                sx={{ p: 2, backgroundColor: "grey.100" }}
                gap={1.5}
              >
                {Object.keys(methods.formState.dirtyFields).length > 0 ? (
                  <Typography variant="subtitle2" color="grey.700">
                    You have unsaved changes.
                  </Typography>
                ) : null}
                <Button
                  variant="secondary"
                  disabled={
                    !Object.keys(methods.formState.dirtyFields).length ||
                    methods.formState.isSubmitting
                  }
                  onClick={handleReset}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    !Object.keys(methods.formState.dirtyFields).length ||
                    methods.formState.isSubmitting
                  }
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </TabContext>
        </Box>
      </FormProvider>
      <Dialog fullWidth={true} maxWidth={"lg"} open={imageCropperOpen} onClose={handleClose}>
        <DialogTitle>Adjust Your Logo</DialogTitle>
        <DialogContent sx={{ p: 0, m: 0 }}>
          <Cropper
            src={logoUrl}
            style={{ width: "100%" }}
            // Cropper.js options
            dragMode={"none"}
            aspectRatio={1 / 1}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCommitCropData}>
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
