import { getDisplayNamePlaceholder } from "@/components/leaderboard/fakeNames";
import { ViewDeferred } from "@/components/routes/LazyUser";
import { columnHelper, StrokePlayTable } from "@/components/routes/StrokePlayTable";
import { getAdminSimulatorLayoutCollection } from "@/firebase/collections";
import { useLayoutLeaderboard } from "@/hooks/useLeaderboards/useLayoutLeaderboard";
import { useUser } from "@/hooks/useUser";
import { getLeaderboardUserMetadata } from "@/summaryUtils";
import { nthNumber } from "@/utils/common";
import { Avatar, Box, Stack } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { StrokePlayScorecard } from "../leaderboards/StrokePlayScorecard";
import { getGolfScore } from "../leaderboards/utils";

const entryVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.4 } },
};

const layoutVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.2 } },
};

export default function PlayLayoutLeaderboardRoute() {
  const params = useParams();
  const [{ userId }] = useUser();
  const [entries, entriesLoading] = useLayoutLeaderboard(params.layoutId);
  const [layout, layoutLoading] = useDocumentData(
    doc(getAdminSimulatorLayoutCollection(), params.layoutId),
  );
  const columns = useMemo(
    () => [
      columnHelper.accessor("rank", {
        header: "Rank",
        cell: ({ row }) => (
          <Stack
            sx={{
              fontSize: "1.2rem",
              fontWeight: 400,
              color: "grey.500",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row.original.rank}
          </Stack>
        ),
        minSize: 64,
        maxSize: 64,
      }),
      columnHelper.accessor("displayName", {
        header: "Name",
        cell: ({ row }) => (
          <ViewDeferred
            key={row.original.userId}
            deferred={async () => {
              const metadataDoc = await getDoc(getLeaderboardUserMetadata(row.original.userId));
              if (metadataDoc.exists()) {
                const metadata = metadataDoc.data();
                return metadata;
              }
              return {};
            }}
          >
            {(metadata) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <Avatar
                  src={metadata?.photo}
                  alt={
                    metadata?.name ??
                    (row.original.displayName || getDisplayNamePlaceholder(row.original.userId))
                  }
                  sx={{
                    border: "1px solid",
                    borderColor: "primary.light",
                  }}
                />

                <Stack gap={0.5}>
                  <Box sx={{ fontSize: "1rem", fontWeight: 300 }}>
                    {metadata?.name ??
                      (row.original.displayName || getDisplayNamePlaceholder(row.original.userId))}
                  </Box>
                </Stack>
              </Stack>
            )}
          </ViewDeferred>
        ),
      }),
      columnHelper.accessor("scorePerHole", {
        header: "Overall",
        cell: ({ row }) => (
          <Box sx={{ fontSize: "1.8rem", fontWeight: 300, width: "100%", textAlign: "right" }}>
            {getGolfScore(row.original.scorePerHole.reduce((acc, score) => acc + score, 0))}
          </Box>
        ),
        minSize: 64,
        maxSize: 64,
      }),
    ],
    [entries],
  );

  const userEntry = useMemo(() => {
    return entries?.find((entry) => entry.userId === userId);
  }, [entries, userId]);

  return (
    <AnimatePresence mode="wait">
      {entriesLoading || layoutLoading ? (
        "Loading..."
      ) : (
        <Stack alignItems="center" gap={4}>
          <Box
            sx={{
              fontSize: "2.5rem",
              letterSpacing: "-0.175rem",
              fontWeight: 500,
              color: "primary.main",
            }}
          >
            {layout?.name}
          </Box>
          <Stack
            direction={{ mobile: "column", laptop: "row" }}
            sx={{
              display: "flex",
              gap: 4,
              alignItems: { mobile: "center", laptop: "flex-start" },
              justifyContent: { mobile: "inherit", laptop: "center" },
            }}
          >
            <Stack gap={2}>
              <Stack
                component={motion.div}
                variants={layoutVariants}
                initial="initial"
                animate="animate"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "100%",
                  gap: { mobile: 6, desktop: 2 },
                }}
              >
                {entries
                  ?.slice(0, 3)
                  .map((entry) => <StrokePlayScorecard key={entry.id} entry={entry} />)}
              </Stack>
            </Stack>
            <StrokePlayTable columns={columns} data={entries.slice(3, 10)} />
          </Stack>
          {userEntry && (
            <Stack
              gap={4}
              sx={{
                width: "100%",
                pt: { mobile: 2, laptop: 6 },
                borderTop: "2px solid",
                borderColor: "grey.400",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Stack direction={{ mobile: "column", laptop: "row" }} gap={4}>
                <Stack alignItems={{ mobile: "center", laptop: "flex-start" }}>
                  <Box
                    sx={{
                      color: "grey.600",
                      fontSize: "1.8rem",
                      fontWeight: 600,
                      letterSpacing: -0.1,
                      lineHeight: 0.9,
                    }}
                  >
                    YOUR
                  </Box>
                  <Box
                    sx={{
                      color: "grey.600",
                      fontSize: "1.8rem",
                      fontWeight: 600,
                      letterSpacing: -0.1,
                      lineHeight: 0.9,
                    }}
                  >
                    CURRENT
                  </Box>
                  <Box
                    sx={{
                      color: "grey.600",
                      fontSize: "1.8rem",
                      fontWeight: 600,
                      letterSpacing: -0.1,
                      lineHeight: 0.9,
                    }}
                  >
                    RANK
                  </Box>
                </Stack>
                {userEntry.rank > 3 && (
                  <Box sx={{ fontSize: "3rem", fontWeight: 500, color: "primary.main" }}>
                    {`${userEntry.rank}${nthNumber(userEntry.rank)}`}
                  </Box>
                )}
              </Stack>

              <StrokePlayScorecard entry={userEntry} />
            </Stack>
          )}
        </Stack>
      )}
    </AnimatePresence>
  );
}
