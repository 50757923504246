import { useCallback, useState } from "react";
import { BluetoothCalibration, BluetoothServices, DEVICE_PREFIX } from "./consts";
import { handleBatteryLevel, handleTxCharacteristic } from "./listeners";
import {
  setBluetoothCharacteristics,
  setBluetoothDevice,
  setDeviceStatus,
  useBluetoothDevice,
  useDeviceStatus,
} from "./signals";
import { getDeviceInfo, handleErrorLogUpdate, sendNusData, timeout } from "./utils";

export enum BluetoothStatus {
  /**
   * The device is not connected to the computer
   */
  Idle = "idle",
  /**
   * We're searching for a TechDisc device
   */
  Searching = "searching",
  /**
   * We're connecting to the device
   */
  Connecting = "connecting",
  /**
   * We're transferring data to/from the device
   */
  Transferring = "transferring",
  /**
   * We're storing the throw summary
   */
  Storing = "storing",
  /**
   * We're ready to send/receive data
   */
  Ready = "ready",
  /**
   * The device has been disconnected without user intervention
   */
  Disconnected = "disconnected",
}

export type BluetoothHookOptions = {
  setupCharacteristicLisenters?(
    device: BluetoothDevice,
    characteristics: Map<string, BluetoothRemoteGATTCharacteristic>,
  ): Promise<void>;
};

export const useBluetooth = (opts: BluetoothHookOptions = {}) => {
  const { setupCharacteristicLisenters } = opts;
  const [connected, setConnected] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState<{
    deviceId: string | null;
    deviceUid: string | null;
    deviceSoftware: string | null;
  }>();
  const device = useBluetoothDevice();
  const deviceStatus = useDeviceStatus();

  const disconnect = useCallback(() => {
    if (device) {
      device.gatt?.disconnect();
    }
    setDeviceStatus(BluetoothStatus.Idle);
  }, [device]);

  const requestBluetoothDevice = async () => {
    setDeviceStatus(BluetoothStatus.Searching);
    console.log("Requesting Bluetooth Device...");

    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: DEVICE_PREFIX }],
        optionalServices: [
          BluetoothServices.Nus, // NUS Service UUID
          BluetoothServices.CyOta,
          BluetoothServices.DeviceInfo,
          BluetoothServices.Battery,
          BluetoothServices.Calibration,
        ],
        acceptAllDevices: false,
      });
      setDeviceStatus(BluetoothStatus.Connecting);

      setBluetoothDevice(device);
      return device;
    } catch (error) {
      console.error("Bluetooth connection failed:", error);
      setDeviceStatus(BluetoothStatus.Idle);
      throw error;
    }
  };

  const connectGattServer = async (device: BluetoothDevice) => {
    if (!device || !device.gatt) return null;

    const server = await timeout(device.gatt.connect(), { ms: 10_000 });

    if (!server.connected) {
      throw "GATT server connection timed out";
    }

    return server;
  };

  const setupCharacteristicListeners = async (
    device: BluetoothDevice,
    characteristics: Map<string, BluetoothRemoteGATTCharacteristic>,
  ) => {
    device?.addEventListener("gattserverdisconnected", (event) => {
      console.log(event);
      setConnected(false);
      setDeviceStatus(BluetoothStatus.Disconnected);
    });

    const txChar = characteristics.get("tx");
    const rxChar = characteristics.get("rx");
    const errorChar = characteristics.get("error");
    const batteryChar = characteristics.get("battery");
    const debugNumbersChar = characteristics.get("debug.numbers");
    const debugVectorsChar = characteristics.get("debug.vectors");
    const macChar = characteristics.get("mac");
    const uidChar = characteristics.get("uid");
    const infoChar = characteristics.get("info");

    const sendMessage = async (message: string) => {
      if (device && rxChar) {
        await sendNusData(device, rxChar, message);
      } else {
        console.warn("Attempted to send NUS data but no device was found.");
      }
    };

    if (device && txChar) {
      txChar.addEventListener("characteristicvaluechanged", async (event: Event) => {
        setDeviceStatus(BluetoothStatus.Transferring);
        await (
          await handleTxCharacteristic(device, characteristics, sendMessage)
        )(event);
      });
      await txChar.startNotifications();
    }

    if (batteryChar) {
      const batteryLevel: DataView = await batteryChar.readValue();

      // const batteryPercent = batteryLevel.getUint8(0)
      // handleBatteryLevel({ batteryLevel })(batteryPercent)

      batteryChar.addEventListener(
        "characteristicvaluechanged",
        handleBatteryLevel({ batteryLevel }),
      );

      await batteryChar.startNotifications();
    }

    if (errorChar) {
      try {
        const errorLog: DataView = await errorChar.readValue();
        handleErrorLogUpdate(errorLog);
        errorChar.addEventListener("characteristicvaluechanged", (event) =>
          // @ts-expect-error
          handleErrorLogUpdate(event.target?.value),
        );
        await errorChar.startNotifications();
      } catch (error) {
        console.info("old devices do not support error logs", error);
      }
    }

    if (debugNumbersChar && debugVectorsChar) {
      const { deviceId, deviceUid } = await getDeviceInfo(macChar, uidChar);
      const softwareRev: DataView | undefined = await infoChar?.readValue();
      const decoder = new TextDecoder();
      const deviceSoftware = decoder.decode(softwareRev);
      setDeviceInfo({ deviceId, deviceUid, deviceSoftware });
      await debugNumbersChar.startNotifications();
      await debugVectorsChar.startNotifications();
    }

    if (setupCharacteristicLisenters) {
      await setupCharacteristicLisenters(device, characteristics);
    }
    setConnected(true);
    setDeviceStatus(BluetoothStatus.Ready);

    console.log("Notifications started");
  };

  const requestGattServices = async (server: BluetoothRemoteGATTServer) => {
    const nusService = await timeout(server.getPrimaryService(BluetoothServices.Nus), {
      ms: 10000,
    });
    if (!nusService) {
      console.log("NUS service not found");
      return;
    }

    const services = await Promise.all(
      [BluetoothServices.DeviceInfo, BluetoothServices.Battery, BluetoothServices.Calibration].map(
        (service) => server.getPrimaryService(service),
      ),
    );

    console.log("Found NUS service: " + nusService.uuid);
    console.log("Found RX characteristic");
    console.log("Found TX characteristic");

    return [nusService, ...services];
  };

  const requestGattCharacteristics = async (services: BluetoothRemoteGATTService[]) => {
    const [nusService, infoService, batteryService, calibrateService] = services;

    const chars = await Promise.all([
      infoService.getCharacteristic(BluetoothServices.SoftwareRevision),
      timeout(nusService.getCharacteristic(BluetoothServices.NusCharacteristicRx), { ms: 10000 }),
      timeout(nusService.getCharacteristic(BluetoothServices.NusCharacteristicTx), { ms: 10000 }),
      batteryService.getCharacteristic(BluetoothServices.BatteryLevel),
      calibrateService.getCharacteristic(BluetoothCalibration.ErrorRx),
      calibrateService.getCharacteristic(BluetoothCalibration.Mac),
      calibrateService.getCharacteristic(BluetoothCalibration.DeviceUid),
      calibrateService.getCharacteristic(BluetoothCalibration.DebugNumbers),
      calibrateService.getCharacteristic(BluetoothCalibration.DebugVectors),
      calibrateService.getCharacteristic(BluetoothCalibration.Accel0),
      calibrateService.getCharacteristic(BluetoothCalibration.Accel1),
      calibrateService.getCharacteristic(BluetoothCalibration.Accel2),
      calibrateService.getCharacteristic(BluetoothCalibration.Gyro),
    ]);

    const characteristics = new Map<string, BluetoothRemoteGATTCharacteristic>(
      [
        "info",
        "rx",
        "tx",
        "battery",
        "error",
        "mac",
        "uid",
        "debug.numbers",
        "debug.vectors",
        "accel0",
        "accel1",
        "accel2",
        "gyro",
      ].map((char, index) => [char, chars[index]]),
    );

    return characteristics;
  };

  const initGattServer = async (device: BluetoothDevice) => {
    console.groupCollapsed("GATT Server/Services/Characteristics");
    console.log("Connecting to GATT server...");

    try {
      const server = await connectGattServer(device);

      if (!server) {
        console.warn("Unable to connect to bluetooth");
        return;
      }

      console.log("Connected to GATT server:", server);
      console.log("Loading NUS service...");

      const services = await requestGattServices(server);
      console.log("services", services);
      const characteristics = await requestGattCharacteristics(services);
      console.log("characteristics", characteristics);

      return { services, characteristics };
    } catch (error) {
      console.error(error);
    } finally {
      console.groupEnd();
    }
  };

  const connect = async () => {
    console.groupCollapsed("Bluetooth");

    const device = await requestBluetoothDevice();
    console.log("Found " + device?.name);

    if (!device?.gatt) {
      console.log("No GATT server found on device");

      return;
    }

    const result = await initGattServer(device);

    if (result) {
      const { characteristics } = result;

      while (!characteristics.size) {
        await new Promise((r) => setTimeout(r, 100));
      }
      setBluetoothCharacteristics(characteristics);
      console.groupEnd();

      await setupCharacteristicListeners(device, characteristics);
    }

    //   if (lastUpload) {
    //     setIndex(0)
    //     setTimeout(
    //       () =>
    //         downloadFlight(flightDownloading, sendMessage),
    //       1_000,
    //     )
    //   }
    // } catch (error) {
    //   console.log('' + error)
    //   setConnected(false)
    //   setTransferring(false)

    //   if (device) {
    //     device.gatt.disconnect()
    //     setTimeout(async () => {
    //       if (device === null || connected) {
    //         lastDisconnect = 0
    //         return
    //       }

    //       if (lastDisconnect === 0) {
    //         // we are starting our search for a new connection
    //         lastDisconnect = Date.now()
    //       } else if (Date.now() - lastDisconnect > 60 * 60 * 1000) {
    //         // we have been disconnected for 60 minutes, so we give up reconnecting
    //         console.warn('Searching timed out. Disconnecting.')
    //         console.warn({
    //           message: 'Connection lost: ' + device.name,
    //           intent: 'warning',
    //         })
    //         device?.gatt?.disconnect()
    //       }

    //       if (device == null || connected) {
    //         lastDisconnect = 0
    //         return
    //       }

    //       const millis = Date.now() - lastDisconnect
    //       console.log('Attempt Reconnect from timer. Disconnected for: ' + millis + 'ms')

    //       await connect()
    //     }, 1_000)
    //   }
    // }
  };

  return {
    connect,
    disconnect,
    ready: deviceStatus === BluetoothStatus.Ready,
    connected,
  };
};
