import naturalSort from "natural-sort";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import { useMemo, useState } from "react";
import { Table } from "rsuite";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import {
  or,
  and,
  collection,
  getFirestore,
  limit,
  query,
  Query,
  QueryConstraint,
  where,
  QueryFilterConstraint,
  QueryNonFilterConstraint,
  orderBy,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { getAuth } from "firebase/auth";
import { useCollection } from "react-firebase-hooks/firestore";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthState } from "react-firebase-hooks/auth";
import { BuildDevice } from "../model/device";
import { format } from "date-fns";
import { exportJSONToCSV } from "../utils/csvUtil";
import { CoreStatsAndId } from "../model/throwSummary";

const { Column, HeaderCell, Cell } = Table;

type SortType = "desc" | "asc";

function downloadCsv(docs: BuildDevice[]) {
  const toExport = docs.map((t) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const date =
      typeof t.firstConnectedTime === "number"
        ? new Date(t.firstConnectedTime)
        : t.firstConnectedTime?.toDate();
    const localDateString = date?.toLocaleString("en-US", options);

    const calibrationTime: number | undefined = t.lastCalibration?.calibrationTime;
    const calibrationDate = calibrationTime ?? new Date().toLocaleString("en-US", options);
    return {
      uid: t.uid,
      mac: t.mac,
      serialNumber: t.serialNumber,
      orderNumber: t.orderNumber,
      calibrationTime: calibrationDate,
      firstConnectedTime: localDateString,
      firstConnectedUser: t.firstConnectedUser,
      initialSoftwareRev: t.initialSoftwareRev,
      color: t.color,
      mold: t.mold,
      weight: t.weight,
    };
  });
  exportJSONToCSV(toExport, "devices");
}

function DevicesTable(props: { devices: BuildDevice[]; isLoading?: boolean }) {
  const [sortColumn, setSortColumn] = useState<keyof BuildDevice>("serialNumber");
  const [sortType, setSortType] = useState<SortType | undefined>("desc");
  const data: BuildDevice[] = useMemo(() => {
    return props.devices.sort((a, b) => {
      let x = a[sortColumn] ?? "";
      let y = b[sortColumn] ?? "";
      if (sortColumn === "lastCalibration") {
        x = a.lastCalibration?.calibrationTime ?? 0;
        y = b.lastCalibration?.calibrationTime ?? 0;
      }
      if (sortColumn === "serialNumber") {
        return naturalSort({ direction: sortType === "asc" ? undefined : "desc" })(
          x as string,
          y as string,
        );
      }
      if (sortType === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      } else {
        return x < y ? 1 : x > y ? -1 : 0;
      }
    });
  }, [sortColumn, sortType, props.devices]);

  return (
    <Table<BuildDevice, keyof BuildDevice>
      id={"analysis-throw-table"}
      style={{ fontSize: "0.9rem" }}
      virtualized
      height={800}
      data={data}
      rowKey={"uid"}
      loading={props?.isLoading}
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={(sortColumn, sortType) => {
        setSortColumn(sortColumn as keyof BuildDevice);
        setSortType(sortType);
      }}
    >
      <Column sortable flexGrow={1}>
        <HeaderCell>UID</HeaderCell>
        <Cell dataKey="uid">
          {(rowData) => (
            <a target="_blank" href={"/device/" + rowData.uid} rel="noreferrer">
              {rowData.uid}
            </a>
          )}
        </Cell>
      </Column>
      <Column sortable flexGrow={1}>
        <HeaderCell>Last Calibration</HeaderCell>
        <Cell dataKey="lastCalibration">
          {(rowData) =>
            rowData.lastCalibration
              ? format(new Date(rowData.lastCalibration.calibrationTime), "yyyy-MM-dd HH:mm")
              : null
          }
        </Cell>
      </Column>
      <Column sortable flexGrow={1}>
        <HeaderCell>Mac</HeaderCell>
        <Cell dataKey="mac">{(rowData) => rowData.mac}</Cell>
      </Column>
      <Column sortable flexGrow={1}>
        <HeaderCell>Serial Number</HeaderCell>
        <Cell dataKey="serialNumber">{(rowData) => rowData.serialNumber}</Cell>
      </Column>
      <Column sortable flexGrow={1}>
        <HeaderCell>Order Number</HeaderCell>
        <Cell dataKey="orderNumber">{(rowData) => rowData.orderNumber}</Cell>
      </Column>
    </Table>
  );
}

export function DevicesDashboard(props: object) {
  const [user, userLoading, userError] = useAuthState(getAuth(firebaseApp));
  const [search, setSearch] = useState<string>("");
  const q: Query = collection(getFirestore(firebaseApp), "/devices");

  const filters: QueryNonFilterConstraint[] = [];
  if (search !== "*") {
    filters.push(limit(20));
  }

  const searchValid = search && search.length > 0 && search !== "*";

  let composite = or();
  const next = !searchValid
    ? ""
    : search.slice(0, search.length - 1) +
      String.fromCharCode(search.charCodeAt(search.length - 1) + 1);
  const serialQ = query(
    q,
    and(
      where("serialNumber", ">=", search),
      where("serialNumber", "<", searchValid ? next : "\uffff"),
    ),
    ...filters,
    orderBy("serialNumber", "desc"),
  );
  const uidQ = query(q, and(where("uid", ">=", search), where("uid", "<", next)), ...filters);
  const macQ = query(q, and(where("mac", ">=", search), where("mac", "<", next)), ...filters);
  const orderQ = query(
    q,
    and(where("orderNumber", ">=", search), where("orderNumber", "<", next)),
    ...filters,
  );

  const userQ = query(q, where("userIdsThrown", "array-contains", search));

  const [valueUid, loadingUid, errorUid] = useCollection(uidQ);
  const [valueMac, loadingMac, errorMac] = useCollection(macQ);
  const [valueSerial, loadingSerial, errorSerial] = useCollection(serialQ);
  const [valueOrder, loadingOrder, errorOrder] = useCollection(orderQ);
  const [valueUser, loadingUser, errorUser] = useCollection(userQ);

  const isLoading = loadingUid || loadingMac || loadingSerial || loadingOrder || loadingUser;

  if (errorUid || errorMac || errorSerial || errorOrder || errorUser) {
    return (
      <div>
        Error:
        {errorUid?.message || errorMac?.message || errorSerial?.message || errorOrder?.message}
      </div>
    );
  }

  const mergedDocsMap = new Map();
  [valueUid, valueMac, valueSerial, valueOrder, valueUser].forEach((snap) => {
    snap?.docs.forEach((doc) => {
      mergedDocsMap.set(doc.id, doc.data());
    });
  });

  const devices = Array.from(mergedDocsMap.values());

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField
              id="device_search"
              label="Prefix Search (case sensitive)"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value ?? "")}
              placeholder={"* loads all devices"}
            />
          </Grid>
        </Grid>
        <Button
          startIcon={<AddIcon />}
          style={{
            width: "fit-content",
            marginRight: "auto",
          }}
          color="success"
          variant="contained"
          href={"/device"}
        >
          Add New Device
        </Button>

        <DevicesTable devices={devices ?? []} isLoading={isLoading || userLoading} />
        <Button
          startIcon={<FileDownloadIcon />}
          style={{
            width: "fit-content",
            marginRight: "auto",
          }}
          color="primary"
          variant="contained"
          onClick={() => downloadCsv(devices ?? [])}
        >
          Export Devices CSV
        </Button>
      </Paper>
    </Container>
  );
}
