import { add, div, gt, lt, mul, sub } from "../../utils/math";
import CRC32 from "crc-32";
import { UploadMetadata, getStorage, ref, uploadBytes } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

import {
  VERSION_START_Q,
  VERSION_MAG,
  DATA_LEN_V6,
  DATA_LEN_V5,
  DeviceView,
  MAX_FORMAT_VERSION,
  MAX_HARDWARE_VERSION,
  NUM_POINTS,
  VERSION_8K_DPS,
  CRC_SKIP_BYTES,
  DeviceBuffer,
  VERSION_CRC,
  DELAY_MS_BEFORE_FETCH_FLIGHT,
  TRANSFER_FORMAT_VERSION,
} from "./consts";
import { getDeviceId, getDeviceUid } from "./utils";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { ThrowUploadMetadata } from "../../model/throwSummary";
import { setDeviceStatus } from "./signals";
import { BluetoothStatus } from "./useBluetooth";
import { toast } from "sonner";

let index = 0;
let transferFormatVersion = TRANSFER_FORMAT_VERSION;
let transferFlightType = 0;
let lastNotificationMs = 0;
let lastTransferMs = 0;
let lastUpload: number | null = null;
let flightDownloading: NodeJS.Timeout | null = null;
let lastUploadCrc: number | null = null;

export const handleTxCharacteristic =
  async (
    device: BluetoothDevice,
    characteristics: Map<string, BluetoothRemoteGATTCharacteristic>,
    sendMessage: (message: string) => Promise<void>,
  ) =>
  async (event: Event) => {
    const firebaseApp = getApp();
    const auth = getAuth(firebaseApp);
    const storage = getStorage(firebaseApp);
    const analytics = getAnalytics(firebaseApp);

    let nacking = false;
    const now = Date.now();
    const value = (event.target as BluetoothRemoteGATTCharacteristic)?.value;

    if (value) {
      console.log(`Notification size: ${value.byteLength} bytes`);

      const timeoutMs = transferFormatVersion <= VERSION_START_Q ? 5000 : 2500;
      const deviceTimeout = index !== 0 && gt(sub(now, lastNotificationMs), timeoutMs);

      if (deviceTimeout) {
        console.log("Starting a new transfer due to timeout", index);
        index = 0;
      }

      lastNotificationMs = now;
      if (index === 0) {
        lastTransferMs = now;
      }

      const DATA_LEN = transferFormatVersion >= VERSION_MAG ? DATA_LEN_V6 : DATA_LEN_V5;

      let bytesToTransfer = DATA_LEN;
      bytesToTransfer += 4;

      if (value.byteLength === 4 && index !== DATA_LEN && index + 4 !== DATA_LEN) {
        // this is an out of place CRC, next notification will be a new transfer
        console.log("> Out of place CRC: " + index);
        index = 0;
        nacking = false;
        return;
      }

      for (let i = 0; i < value.byteLength; i++) {
        if (index >= bytesToTransfer) {
          console.log("> Extra Data. bytes: " + value.byteLength);

          // sending a nack will have the device begin a new transfer
          // if (transferFormatVersion >= VERSION_8K_DPS) {
          if (!nacking) {
            sendMessage("nack");
            nacking = true;
          }
          // }
          return;
        }
        DeviceView.setUint8(index++, value.getUint8(i));
      }

      if (index === value.byteLength) {
        // is the first batch of data
        const formatVersion = DeviceView.getUint8(0);
        const hardwareVersion = DeviceView.getUint8(1);
        const startIndex = DeviceView.getUint16(2, true);
        // const timeSinceThrow = DeviceView.getUint16(4, true) * 10
        const flightType = DeviceView.getUint8(6);
        const numPoints = DeviceView.getUint8(7) * 100;
        if (
          formatVersion > MAX_FORMAT_VERSION ||
          hardwareVersion > MAX_HARDWARE_VERSION ||
          startIndex >= NUM_POINTS ||
          (formatVersion >= VERSION_8K_DPS && numPoints != NUM_POINTS)
        ) {
          // if batch starts with an invalid header, we just discard it
          console.log("> INVALID THROW DATA HEADER");
          if (!nacking) {
            await sendMessage("nack");
            nacking = true;
          }
          index = bytesToTransfer;
          return;
        }

        transferFormatVersion = formatVersion;
        transferFlightType = flightType;
      }

      if (index === bytesToTransfer) {
        console.log("> Transfer Complete");
        const buf = DeviceBuffer.slice(0, DATA_LEN);
        const crc: number = CRC32.buf(new Uint8Array(buf, CRC_SKIP_BYTES));
        if (transferFormatVersion >= VERSION_CRC) {
          const passedCrc = DeviceView.getInt32(DATA_LEN, true);
          if (crc !== passedCrc) {
            // crc is wrong
            console.log("> CRC is WRONG");
            index = 0;
            return;
          }
        }

        if (lastUploadCrc && crc == lastUploadCrc) {
          // data sent twice
          console.log("> Data sent twice. acking again.");
          await sendMessage("ack");
          return;
        }

        const bleTransferMillis = Date.now() - lastTransferMs;

        console.log("> Transfer complete. Storing data...");
        setDeviceStatus(BluetoothStatus.Ready);

        lastUploadCrc = crc;
        let seconds = Math.floor(lastTransferMs / 1000);
        let suffix = "throw";
        const isThrow = transferFlightType === 0;

        if (!isThrow) {
          if (flightDownloading && lastUpload) {
            seconds = lastUpload;
            suffix = "flight";
            clearTimeout(flightDownloading);
            flightDownloading = null;
          } else {
            console.error("Recieved a flight when we weren't expecting one.");
            return;
          }
        }

        const throwRef = ref(storage, `/raw-throws/${auth.currentUser?.uid}/${seconds}.${suffix}`);

        console.log("ID", seconds);

        const uidChar = characteristics.get("uid");
        const macChar = characteristics.get("mac");

        const deviceId = macChar ? (device?.id ?? (await getDeviceId(device, macChar))) : undefined;
        const deviceUid = uidChar ? await getDeviceUid(uidChar) : undefined;
        const uploadthrowMillis = Date.now() - lastTransferMs - bleTransferMillis;

        // TODO: suspect
        if (!deviceId) {
          console.error("No device id");
          return;
        }
        // TODO: suspect
        if (!deviceUid) {
          console.error("No device uid");
          return;
        }
        const customMetadata: ThrowUploadMetadata = {
          deviceId: deviceId,
          userId: auth.currentUser?.uid,
          deviceBeginTransfer: lastTransferMs.toString(),
          bluetoothMillis: bleTransferMillis.toString(),
          uploadMillis: uploadthrowMillis.toString(),
          client: "web",
        };
        if (deviceUid) {
          customMetadata.deviceUid = deviceUid;
        }
        // if (deviceSoftware) {
        //   customMetadata.deviceSoftware = deviceSoftware
        // }
        const uploadMetadata: UploadMetadata = {
          customMetadata,
        };

        try {
          await uploadBytes(throwRef, buf, uploadMetadata);

          //   setTransferring(false)
          // The bytes were successfully uploaded,
          // We ack the transfer to the device and trigger a summarize
          // Also store the stub object with tags and uploadTime

          if (isThrow) {
            toast.success("Throw uploaded.", {
              duration: 2_000,
            });
          }

          index = 0;
          lastUpload = seconds;
          if (!isThrow) {
            // Clear out last upload after we have fetched the flight.
            lastUpload = 0;
            logEvent(analytics, "upload_flight", { ...customMetadata });
          } else {
            logEvent(analytics, "upload_throw", { ...customMetadata });
            logEvent(analytics, "throw_upload_latency", {
              ...customMetadata,
            });
          }
          if (isThrow) {
            await sendMessage("ack");
            await new Promise((r) => setTimeout(r, DELAY_MS_BEFORE_FETCH_FLIGHT));
            // downloadFlight(flightDownloading, sendMessage);
          }
        } catch (error) {
          //   setTransferring(false)
          console.error("Upload failed: " + error);
          logEvent(analytics, "upload_failed", {
            error,
            path: throwRef.fullPath,
            ...customMetadata,
          });
          console.log({
            message: "Throw failed to store. Make sure internet is working.",
            intent: "danger",
          });
        }
      }
    }
  };

export const handleBatteryLevel =
  ({ batteryLevel }: { batteryLevel: DataView }) =>
  () => {
    const batteryPercent = batteryLevel.getUint8(0);
    let avg = 0;
    const alpha = div(1.0, 10.0);
    const alpha2 = div(1.0, 15.0);
    avg = mul(add(mul(Math.floor(avg), alpha), avg), 1 - alpha);
    avg = mul(add(mul(batteryPercent, alpha2), avg), 1 - alpha2);
    if (lt(avg, 0)) {
      return 0;
    }
    return avg;
  };

export const handleCalibrationValues =
  (characteristics: Map<string, BluetoothRemoteGATTCharacteristic>) => () => {
    const debugNumbers = characteristics.get("debug.numbers");
    const debugVectors = characteristics.get("debug.vectors");
  };
