import { useState } from "react";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { IosShare, Link } from "@mui/icons-material";
import { LeaderboardView } from "./Leaderboards";
import { toast } from "sonner";

// LeaderboardHeader.tsx
interface LeaderboardHeaderProps {
  isPowerUser: boolean;
  view: LeaderboardView;
  setView: (view: LeaderboardView) => void;
  userId?: string;
}

const LeaderboardHeader: React.FC<LeaderboardHeaderProps> = ({
  isPowerUser,
  view,
  setView,
  userId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const shareOpen = Boolean(anchorEl);
  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleShareClose = () => {
    setAnchorEl(null);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/leaderboards/${userId}`);
    toast.info("Copied link to clipboard");
    handleShareClose();
  };
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={2} alignItems="flex-end">
        <Typography variant="h1" fontSize={28} fontWeight={500}>
          Leaderboards
        </Typography>
        <IconButton
          id="leaderboard-share-button"
          size="small"
          sx={{ mb: "0.1rem" }}
          onClick={handleShareClick}
        >
          <IosShare />
        </IconButton>

        <Menu
          id="leaderboard-share-menu"
          aria-labelledby="leaderboard-share-button"
          anchorEl={anchorEl}
          open={shareOpen}
          onClose={handleShareClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleCopyLink}>
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            Copy link
          </MenuItem>
        </Menu>
      </Stack>
      {isPowerUser && (
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={(_, newView) => newView && setView(newView)}
        >
          <ToggleButton value={"STORE"}>Store</ToggleButton>
          <ToggleButton value={"GLOBAL"}>Global</ToggleButton>
        </ToggleButtonGroup>
      )}
    </Stack>
  );
};

export { LeaderboardHeader };
