import { useFormContext } from "react-hook-form";
import { Stack, FormControl, FormLabel, TextField } from "@mui/material";
import Title from "../../../dashboard/Title";
import FormErrorText from "./FormErrorText";

export default function StoreContactForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack gap={3}>
      <Title variant="secondary">Contact</Title>
      <Stack
        sx={{ display: "grid", gridTemplateColumns: { md: "repeat(3, 1fr)" }, gap: { md: 2 } }}
      >
        <FormControl>
          <FormLabel sx={{ mb: 1 }}>Phone Number</FormLabel>
          <TextField size="small" inputProps={register("phone")} placeholder="###-###-####" />
          <FormErrorText field="phone" errors={errors} />
        </FormControl>
        <FormControl>
          <FormLabel sx={{ mb: 1 }}>Email</FormLabel>
          <TextField size="small" inputProps={register("email")} placeholder="shop@example.com" />
          <FormErrorText field="email" errors={errors} />
        </FormControl>
      </Stack>
    </Stack>
  );
}
