import { useVideoDevice } from "@/components/routes/video/VideoDeviceProvider";
import { VideoSetup } from "@/components/routes/video/VideoSetup";
import { Container, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { VideoCaptureDashboard } from "./VideoCaptureDashboard";

export default function VideoCaptureRoute() {
  const { activeDevice } = useVideoDevice();

  return (
    <Container sx={{ my: 4 }}>
      <Stack gap={4}>
        <AnimatePresence mode="wait">
          <Stack component={motion.div} initial="initial" gap={2}>
            {activeDevice ? <VideoCaptureDashboard /> : <VideoSetup />}
          </Stack>
        </AnimatePresence>
      </Stack>
    </Container>
  );
}
