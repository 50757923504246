import PlayLayouts from "@/components/leaderboards/PlayLayouts";
import { containerVariants } from "@/components/routes/variants";
import { Stack } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import { useCollectionData } from "react-firebase-hooks/firestore";
import PlayEvents from "../leaderboards/PlayEvents";

import { getAdminSimulatorLayoutCollection, getPlayEventsCollection } from "@/firebase/collections";
import { useLayoutLeaderboards } from "@/hooks/useLeaderboards/useLayoutLeaderboard";
import { query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useSimulatorPermissions } from "@/hooks/useSimulatorPermissions";
import { CircularProgress } from "@mui/material";

export default function PlayLeaderboardsRoute() {
  const [{ allowedLayouts }, permissionsLoading] = useSimulatorPermissions();
  const [playEvents = [], playEventsLoading] = useCollectionData(getPlayEventsCollection());
  const [layouts, layoutsLoading] = useCollectionData(
    query(
      getAdminSimulatorLayoutCollection(),
      where("isPublic", "==", true),
      where("isEventLayout", "==", false),
    ),
  );
  const [layoutLeaderboards, layoutLeaderboardsLoading] = useLayoutLeaderboards();

  const accessibleLayouts = useMemo(
    () =>
      layouts?.filter((layout) =>
        allowedLayouts?.some((allowedLayout) => allowedLayout.id === layout?.id),
      ),
    [layouts, allowedLayouts],
  );

  const layoutsWithLeaderboards = useMemo(
    () =>
      accessibleLayouts
        ?.map((layout) =>
          layoutLeaderboards?.get(layout?.id)?.length === 0
            ? null
            : {
                ...layout,
                leaderboard: layoutLeaderboards.get(layout.id),
              },
        )
        .filter((layout) => layout),
    [accessibleLayouts, layoutLeaderboards],
  );

  const isLoading =
    layoutsLoading || layoutLeaderboardsLoading || playEventsLoading || permissionsLoading;

  return (
    <AnimatePresence mode="wait">
      <Stack
        direction={{ mobile: "column", tablet: "row" }}
        component={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        flexWrap={"wrap"}
        sx={{
          gap: { tablet: 8, mobile: 4 },
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <PlayEvents playEvents={playEvents} />
            <PlayLayouts layouts={layoutsWithLeaderboards} />
          </>
        )}
      </Stack>
    </AnimatePresence>
  );
}
