import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";

export enum BluetoothStatus {
  IDLE = "idle",
  CONNECTED = "connected",
  READY = "ready",
  DISCONNECTED = "disconnected",
  TRANSFERING = "transfering",
}
export const [throwActivityChanged$, setThrowActivity] = createSignal<number>();
export const [useThrowActivity, throwActivity$] = bind(throwActivityChanged$, 0);

export const [bluetoothStatusChanged$, setBluetoothStatus] = createSignal<BluetoothStatus>();
export const [useBluetoothStatus, bluetoothStatus$] = bind(
  bluetoothStatusChanged$,
  BluetoothStatus.IDLE,
);
