import { useUserRoles } from "@/hooks/useUserRoles";
import { User } from "firebase/auth";
import { AuthClaim } from "../components/admin/PermissionsManager";
import { USER_ROLES } from "../components/user/consts";
import { UserRoles } from "../components/user/types";
import { LIVE_USER_ID } from "../dashboard/liveEvents";
import { PremiumFeature, useEntitlements } from "../stripe";
import { ADMIN_USER_IDS, BETA_USER_IDS, getTrueUserId } from "../summaryUtils";

export default function useRoleCheck(user: User | null | undefined, userLoading?: boolean) {
  const { entitlements, loading: entitlementsLoading } = useEntitlements(user);
  const { userRoles: newUserRoles } = useUserRoles(user);

  const isLiveAccount = user?.uid === LIVE_USER_ID;
  const isBetaUser = Boolean(
    entitlements?.includes(AuthClaim.beta) || BETA_USER_IDS.has(getTrueUserId()),
  );
  const isDebugThrows = Boolean(entitlements?.includes(AuthClaim.debugThrows));
  const isEarlyAccessUser = Boolean(entitlements?.includes(AuthClaim.earlyAccess));
  const isPremiumStore = Boolean(entitlements?.includes(PremiumFeature.STORE));
  const isProUser = Boolean(entitlements?.includes(PremiumFeature.PRO));
  const isPlayUser = Boolean(entitlements?.includes(PremiumFeature.PLAY));
  const isStore = Boolean(entitlements?.includes(AuthClaim.store) || isPremiumStore);
  const isManager = Boolean(entitlements?.includes(AuthClaim.manager));
  const isManufacturing = Boolean(entitlements?.includes(AuthClaim.devices));
  const isSupport = Boolean(entitlements?.includes(AuthClaim.debug));
  const isDeveloper = Boolean(entitlements?.includes(AuthClaim.dev));
  const isAdmin = Boolean(
    ADMIN_USER_IDS.has(user?.uid || "") || entitlements?.includes(AuthClaim.admin),
  );
  const isUnityDeveloper = Boolean(newUserRoles?.some((role) => role.claimToken === "unity_dev"));

  const roleChecks = [
    USER_ROLES.ANONYMOUS_USER,
    user && USER_ROLES.USER,
    isProUser && USER_ROLES.PRO_USER,
    isBetaUser && USER_ROLES.BETA_USER,
    isEarlyAccessUser && USER_ROLES.EARLY_ACCESS_USER,
    isDebugThrows && USER_ROLES.DEBUG_THROWS,
    isStore && USER_ROLES.STORE,
    isPremiumStore && USER_ROLES.PREMIUM_STORE,
    isAdmin && USER_ROLES.ADMIN,
    isManager && USER_ROLES.MANAGER,
    isSupport && USER_ROLES.SUPPORT,
    isManufacturing && USER_ROLES.MANUFACTURING,
    isDeveloper && USER_ROLES.DEVELOPER,
    isUnityDeveloper && USER_ROLES.UNITY_DEVELOPER,
    isPlayUser && USER_ROLES.PLAY_USER,
  ];

  const userRolesLoading = Boolean(userLoading || entitlementsLoading || (user && !entitlements));

  const userRoles = isAdmin
    ? Object.values(USER_ROLES)
    : (roleChecks.filter((check) => Boolean(check)) as UserRoles);

  return {
    userClaims: entitlements,
    userRoles,
    userRolesLoading,
    isLiveAccount,
    isBetaUser,
    isEarlyAccessUser,
    isStore,
    isPremiumStore,
    isDebugThrows,
    isAdmin,
    isManager,
    isManufacturing,
    isSupport,
    isDeveloper,
    isProUser,
    isUnityDeveloper,
  };
}
