import { videoClient } from "@/components/routes/video/client";
import { useVideoDevice } from "@/components/routes/video/VideoDeviceProvider";
import { ThrowMetrics } from "@/dashboard/Dashboard2";
import { updateThrowSummary } from "@/firebase/throwSummary/updateThrowSummary";
import { deviceStatusChanged$ } from "@/hooks/useBluetooth/signals";
import { BluetoothStatus } from "@/hooks/useBluetooth/useBluetooth";
import { useRecentThrows } from "@/hooks/useRecentThrows";
import { useUser } from "@/hooks/useUser";
import type { ThrowSummary } from "@/model/throwSummary";
import { trackEvent } from "@/utils/logging";
import {
  createThumbnailUploadTask,
  createVideoUploadTask,
  pauseUploads,
  resumeUploads,
  uploadQueue$,
} from "@/utils/video";
import { Alert, AlertTitle, Box, Stack } from "@mui/material";
import { VideoPlayer } from "@tdisc/video";
import type { UploadTask, UploadTaskSnapshot } from "firebase/storage";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  forkJoin,
  map,
  Observable,
  Subject,
  switchMap,
  withLatestFrom,
} from "rxjs";

const incomingThrow$ = new Subject<ThrowSummary>();
const saveVideo$ = new Subject<{
  throwId: string;
  clipBuffer: ArrayBuffer;
  thumbnail: Blob;
  deviceId: string;
}>();

export function VideoCaptureDashboard() {
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [{ trueUserId }] = useUser();
  const { latestThrow, loading: latestThrowLoading } = useRecentThrows({
    userId: trueUserId!,
    skip: !trueUserId,
  });
  const { getVideoSettings, activeDevice } = useVideoDevice();

  const [videoInfo, setVideoInfo] = useState<{
    clipUrl: string;
    thumbnailUrl: string;
  } | null>(null);

  // Upload video & update throw
  useEffect(() => {
    if (!trueUserId) return;

    const uploadAndUpdateSubscription = saveVideo$
      .pipe(
        switchMap(({ throwId, clipBuffer, thumbnail, deviceId }) => {
          const videoUpload$ = new Observable<UploadTask>((observer) => {
            const uploadTask = createVideoUploadTask({
              userId: trueUserId,
              throwId,
              filename: "coordinator",
              data: clipBuffer,
            });

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Video upload is ${progress}% done`);
              },
              (error) => {
                trackEvent("video_error", {
                  trueUserId,
                  error,
                });
                observer.error(error);
              },
              () => {
                observer.next(uploadTask);
                observer.complete();
              },
            );
          });

          const thumbnailUpload$ = new Observable<UploadTask>((observer) => {
            const uploadTask = createThumbnailUploadTask({
              userId: trueUserId,
              throwId,
              filename: "coordinator",
              data: thumbnail,
            });
            uploadTask.on(
              "state_changed",
              (snapshot: UploadTaskSnapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Thumbnail upload is ${progress}% done`);
              },
              (error: Error) => {
                trackEvent("upload_thumbnail_error", {
                  trueUserId,
                  error,
                });
                observer.error(error);
              },
              () => {
                observer.next(uploadTask);
                observer.complete();
              },
            );
          });

          return forkJoin({
            videoTask: videoUpload$,
            thumbnailTask: thumbnailUpload$,
          }).pipe(
            map(({ videoTask, thumbnailTask }) => ({
              throwId,
              videoTask,
              thumbnailTask,
              deviceId,
            })),
          );
        }),
      )
      .subscribe(async ({ throwId, videoTask, thumbnailTask }) => {
        trackEvent("upload_throw_video", {
          throwId,
          userId: trueUserId,
          frameRate: getVideoSettings()?.frameRate,
          aspectRatio: getVideoSettings()?.aspectRatio,
        });
        await updateThrowSummary(trueUserId, throwId, {
          videoMetadata: {
            coordinator: {
              aspectRatio: getVideoSettings()?.aspectRatio ?? 16 / 9,
              frameRate: getVideoSettings()?.frameRate ?? 30,
              backswingDuration: 4,
              fullFilePath: videoTask.snapshot.metadata?.fullPath,
              storageSize: videoTask.snapshot.bytesTransferred,
              thumbnailPath: thumbnailTask.snapshot.metadata?.fullPath,
              type: "video",
            },
          },
        });
        trackEvent("video_update_summary", { throwId, userId: trueUserId });
        console.log(`Both uploads completed for throwId: ${throwId}`);
        console.log("Video Snapshot:", videoTask);
        console.log("Thumbnail Snapshot:", thumbnailTask);
      });

    // Original subscription remains the same
    const assignVideoSubscription = incomingThrow$
      .pipe(
        distinctUntilKeyChanged("id"),
        map((incomingThrow) => incomingThrow.id),
        withLatestFrom(uploadQueue$.pipe(distinctUntilChanged())),
      )
      .subscribe(([latestThrowId, { clipBuffer, thumbnail, deviceId }]) => {
        console.log("Saving video", { latestThrowId, clipBuffer, thumbnail, deviceId });
        saveVideo$.next({ throwId: latestThrowId, clipBuffer, thumbnail, deviceId });
      });

    return () => {
      uploadAndUpdateSubscription.unsubscribe();
      assignVideoSubscription.unsubscribe();
    };
  }, [getVideoSettings, trueUserId]);

  // Latest throw
  useEffect(() => {
    if (latestThrow) {
      incomingThrow$.next(latestThrow);
    }
  }, [latestThrow]);

  // Video Clips
  useEffect(() => {
    videoClient.onClipData(async ({ deviceId, thumbnail, clipBuffer }) => {
      pauseUploads();

      const clipUrl = videoClient.createClipUrl(clipBuffer);
      const thumbnailUrl = URL.createObjectURL(thumbnail);

      setVideoInfo({
        clipUrl,
        thumbnailUrl,
      });
      uploadQueue$.next({ clipBuffer, thumbnail, deviceId });
      resumeUploads();
    });
  }, []);

  // BLE
  useEffect(() => {
    const connectedSubscription = deviceStatusChanged$
      .pipe(distinctUntilChanged())
      .subscribe((status) => {
        setDeviceConnected(
          ![
            BluetoothStatus.Disconnected,
            BluetoothStatus.Idle,
            BluetoothStatus.Searching,
            BluetoothStatus.Connecting,
          ].includes(status),
        );
      });

    return () => {
      connectedSubscription.unsubscribe();
    };
  }, []);

  // BLE
  useEffect(() => {
    if (!trueUserId) return;

    const throwTriggerSubscription = deviceStatusChanged$
      .pipe(
        distinctUntilChanged(),
        filter((status) => status === BluetoothStatus.Transferring),
      )
      .subscribe(() => {
        setTimeout(() => {
          if (!activeDevice) return;
          console.log("clip", activeDevice);
          videoClient?.clip(activeDevice.id);
          trackEvent("capture_throw_video", { userId: trueUserId });
        }, 2000);
      });

    return () => {
      throwTriggerSubscription.unsubscribe();
    };
  }, [trueUserId, activeDevice]);

  return (
    <Stack gap={4} width="100%">
      {!deviceConnected && (
        <Alert severity="info">
          <AlertTitle sx={{ m: 0 }}>NOTE</AlertTitle>
          Your TechDisc must remain connected to this tab to capture video.
        </Alert>
      )}
      <ThrowMetrics metricsLoading={latestThrowLoading} throwSummary={latestThrow!} />
      <Box sx={{ position: "relative" }}>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: [0.5, 1], scale: [0.5, 1] }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
              duration: 0.5,
              ease: "anticipate",
            }}
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <VideoPlayer
              autoPlay
              key={videoInfo?.clipUrl}
              src={videoInfo?.clipUrl}
              poster={videoInfo?.thumbnailUrl}
              frameRate={getVideoSettings()?.frameRate}
            />
          </motion.div>
        </AnimatePresence>
        <motion.div
          layoutId="recording-video"
          initial={{
            opacity: 0,
            scale: 0.5,
          }}
          animate={{
            opacity: 0.8,
            scale: 1,
          }}
          whileHover={{
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
            ease: "anticipate",
          }}
          style={{ position: "absolute", top: "8px", right: "8px", width: "30%" }}
        >
          <VideoPlayer autoPlay stream={activeDevice?.stream} />
        </motion.div>
      </Box>
    </Stack>
  );
}
