import { useGlobal } from "@/components/providers/GlobalProvider";
import { getStorePricingPlans, getUserPricingPlans } from "@/firebase/collections";
import type { PricingPlan } from "@/firebase/converters/pricingPlans";
import { useUser } from "@/hooks/useUser";
import {
  createCheckoutSession,
  createPortalLink,
  hasPremiumFeature,
  PremiumFeature,
  useEntitlements,
  useStripeClaim,
} from "@/stripe";
import { trackEvent } from "@/utils/logging";
import { ChevronRight } from "@mui/icons-material";
import { Box, CircularProgress, Container, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

interface PricingTableProps {
  header?: React.ReactNode;
}

export const PricingTable = (props: PricingTableProps) => {
  const { isStore, userRolesLoading } = useGlobal();
  return userRolesLoading ? (
    <CircularProgress />
  ) : isStore ? (
    <StorePricingTable {...props} />
  ) : (
    <UserPricingTable {...props} />
  );
};

const UserPricingTable = (props: PricingTableProps) => {
  const [visibleRates, setVisibleRates] = useState<"monthly" | "yearly">("monthly");
  const [plans, loading] = useCollectionData(getUserPricingPlans());

  return (
    <Container sx={{ my: 4, mt: 8 }}>
      <Stack gap={6}>
        <Stack gap={3} justifyContent="center" alignItems="center">
          {props.header || <UserPricingTableHeader />}
          <RateToggle selectedVisibleRates={visibleRates} onVisibleRatesChange={setVisibleRates} />
        </Stack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mobile: "1fr",
              tablet: "repeat(2, 1fr)",
              laptop: "repeat(3, 1fr)",
            },
            gap: 2,
          }}
        >
          {!loading &&
            plans?.map((plan, index) => (
              <Box
                key={`plan-${index}`}
                sx={{
                  gridRow: {
                    mobile: "auto",
                    tablet: index === 0 ? "span 2" : "auto",
                    laptop: "auto",
                  },
                }}
              >
                <Box
                  sx={{
                    px: 3,
                    py: 3.5,
                    backgroundColor: "white",
                    borderRadius: 2,
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.1)",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack gap={2}>
                    <Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                      >
                        <TierName>{plan.label}</TierName>
                        {visibleRates === "yearly" && plan.pricing.yearly.price > 0 && (
                          <Box
                            initial={{ scale: 0.25 }}
                            animate={{ scale: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            component={motion.div}
                            sx={{
                              py: 0.5,
                              px: 1,
                              borderRadius: 4,
                              border: "2px solid",
                              borderColor: "primary.main",
                              color: "primary.main",
                              fontWeight: 600,
                              fontSize: "1.2rem",
                              letterSpacing: "-0.02rem",
                            }}
                          >
                            <Box sx={{ lineHeight: 1.1, fontSize: "1rem" }}>
                              {`Save ${Math.round(
                                ((plan.pricing.monthly.price * 12 - plan.pricing.yearly.price) /
                                  plan.pricing.yearly.price) *
                                  100,
                              )}%`}
                            </Box>
                          </Box>
                        )}
                      </Stack>
                      <TierPrice plan={plan} visibleRates={visibleRates} />
                    </Stack>
                    <TierFeatures plan={plan} />
                  </Stack>
                  {plan.pricing.monthly.price > 0 && plan.pricing.yearly.price > 0 && (
                    <TierPurchaseButton plan={plan} rate={visibleRates} />
                  )}
                </Box>
              </Box>
            ))}
        </Box>
      </Stack>
    </Container>
  );
};

const StorePricingTable = (props: PricingTableProps) => {
  const [visibleRates, setVisibleRates] = useState<"monthly" | "yearly">("monthly");
  const [plans, loading] = useCollectionData(getStorePricingPlans());

  return (
    <Container sx={{ my: 4, mt: 8 }}>
      <Stack gap={6}>
        <Stack gap={3} justifyContent="center" alignItems="center">
          {props.header || <StorePricingTableHeader />}
          <RateToggle selectedVisibleRates={visibleRates} onVisibleRatesChange={setVisibleRates} />
        </Stack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mobile: "1fr",
              tablet: "repeat(2, minmax(364px, 1fr))",
            },
            gap: 2,
          }}
        >
          {!loading &&
            plans?.map((plan, index) => (
              <Box
                key={`plan-${index}`}
                sx={{
                  gridRow: {
                    mobile: "auto",
                    tablet: index === 0 ? "span 2" : "auto",
                    laptop: "auto",
                  },
                }}
              >
                <Box
                  sx={{
                    px: 3,
                    py: 3.5,
                    backgroundColor: "white",
                    borderRadius: 2,
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.1)",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack gap={2}>
                    <Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                      >
                        <TierName>{plan.label}</TierName>
                        {visibleRates === "yearly" && plan.pricing.yearly.price > 0 && (
                          <Box
                            initial={{ scale: 0.25 }}
                            animate={{ scale: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            component={motion.div}
                            sx={{
                              py: 0.5,
                              px: 1,
                              borderRadius: 4,
                              border: "2px solid",
                              borderColor: "primary.main",
                              color: "primary.main",
                              fontWeight: 600,
                              fontSize: "1.2rem",
                              letterSpacing: "-0.02rem",
                            }}
                          >
                            <Box sx={{ lineHeight: 1.1, fontSize: "1rem" }}>
                              {`Save ${Math.round(
                                ((plan.pricing.monthly.price * 12 - plan.pricing.yearly.price) /
                                  plan.pricing.yearly.price) *
                                  100,
                              )}%`}
                            </Box>
                          </Box>
                        )}
                      </Stack>
                      <TierPrice plan={plan} visibleRates={visibleRates} />
                    </Stack>
                    <TierFeatures plan={plan} />
                  </Stack>
                  {plan.pricing.monthly.price > 0 && plan.pricing.yearly.price > 0 && (
                    <TierPurchaseButton plan={plan} rate={visibleRates} />
                  )}
                </Box>
              </Box>
            ))}
        </Box>
      </Stack>
    </Container>
  );
};

const UserPricingTableHeader = () => {
  return (
    <Box
      sx={{
        letterSpacing: "-0.2rem",
        fontSize: "3rem",
        fontWeight: 200,
        width: { laptop: "500px" },
        textAlign: "center",
        lineHeight: 1.2,
      }}
    >
      Plans that <span style={{ fontWeight: 400 }}>grow</span> with{" "}
      <span style={{ fontWeight: 400 }}>your game</span>.
    </Box>
  );
};

const StorePricingTableHeader = () => {
  return (
    <Box
      sx={{
        letterSpacing: "-0.2rem",
        fontSize: "3rem",
        fontWeight: 200,
        width: { laptop: "500px" },
        textAlign: "center",
        lineHeight: 1.2,
      }}
    >
      Plans that <span style={{ fontWeight: 400 }}>grow</span> with{" "}
      <span style={{ fontWeight: 400 }}>your store</span>.
    </Box>
  );
};

const RateToggle = (props: {
  selectedVisibleRates: "monthly" | "yearly";
  onVisibleRatesChange: (rate: "monthly" | "yearly") => void;
}) => {
  const handleVisibleRatesChange = () => {
    props.onVisibleRatesChange(props.selectedVisibleRates === "monthly" ? "yearly" : "monthly");
  };
  return (
    <Box
      component="button"
      onClick={handleVisibleRatesChange}
      sx={{
        display: "flex",
        p: 0.5,
        gap: 0.5,
        borderRadius: 6,
        border: "1px solid",
        borderColor: "grey.300",
        backgroundColor: "white",
        width: "fit-content",
      }}
    >
      <Box
        sx={{
          position: "relative",
          px: 1.5,
          py: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          zIndex: 1,
          borderRadius: 6,
          color: props.selectedVisibleRates === "monthly" ? "white" : "grey.700",
          transition: "color 0.1s linear",
          transitionDelay: "0.2s",
        }}
      >
        <Box sx={{ zIndex: 1, position: "relative", lineHeight: 1.3 }}>Monthly</Box>
        {props.selectedVisibleRates === "monthly" && (
          <Box
            component={motion.div}
            sx={{
              zIndex: 0,
              position: "absolute",
              top: 1,
              left: 1,
              height: "100%",
              width: "100%",
              borderRadius: 6,
              backgroundColor: "primary.main",
            }}
            layoutId="selected"
          />
        )}
      </Box>
      <Box
        sx={{
          position: "relative",
          px: 1.5,
          py: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          zIndex: 1,
          borderRadius: 4,
          color: props.selectedVisibleRates === "yearly" ? "white" : "grey.700",
          transition: "color 0.1s linear",
          transitionDelay: "0.2s",
        }}
      >
        <Box sx={{ zIndex: 1, position: "relative", lineHeight: 1.3 }}>Yearly</Box>
        {props.selectedVisibleRates === "yearly" && (
          <Box
            component={motion.div}
            sx={{
              zIndex: 0,
              position: "absolute",
              top: 1,
              left: 1,
              height: "100%",
              width: "100%",
              borderRadius: 4,
              backgroundColor: "primary.main",
            }}
            layoutId="selected"
          />
        )}
      </Box>
    </Box>
  );
};

const featureVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const TierFeatures = (props: { plan: PricingPlan }) => (
  <AnimatePresence mode="wait">
    <Stack
      component={motion.ul}
      variants={featureVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 1, staggerChildren: 0.2 }}
      gap={1.5}
    >
      {props.plan.features.map((feat, index) => (
        <Box
          key={`feat-${index}`}
          component={motion.li}
          variants={featureVariants}
          sx={{ fontWeight: 200, fontSize: "1rem", lineHeight: 1.6 }}
        >
          <Stack direction="row" gap={1} alignItems="flex-start">
            <ChevronRight fontSize="small" />
            {feat}
          </Stack>
        </Box>
      ))}
    </Stack>
  </AnimatePresence>
);

const TierName = (props: { children: React.ReactNode; comingSoon?: boolean }) => (
  <Box sx={{ fontSize: "1.6rem", color: "grey.600", fontWeight: 300 }}>{props.children}</Box>
);

const TierPrice = (props: { plan: PricingPlan; visibleRates: "monthly" | "yearly" }) => {
  const isMonthly = props.visibleRates === "monthly";
  const isFree = props.plan.pricing.monthly.price === 0 || props.plan.pricing.yearly.price === 0;
  return (
    <Box sx={{ color: "primary.dark" }}>
      <AnimatePresence mode="wait">
        {isFree ? (
          <Box
            key="free"
            component={motion.div}
            sx={{
              fontSize: "3.5rem",
              letterSpacing: "-0.18rem",
              lineHeight: 1.3,
              fontWeight: 600,
            }}
          >
            Free
          </Box>
        ) : isMonthly ? (
          <Stack
            key="monthly"
            component={motion.div}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            direction="row"
            alignItems="baseline"
          >
            <Box sx={{ fontSize: "2rem", fontWeight: 500 }}>$</Box>
            <Box
              sx={{
                fontSize: "3.5rem",
                letterSpacing: "-0.18rem",
                lineHeight: 1.3,
                fontWeight: 600,
              }}
            >{`${props.plan.pricing.monthly.price}`}</Box>
            <Box sx={{ fontSize: "1.2rem" }}>/mo.</Box>
          </Stack>
        ) : (
          <Stack
            key="yearly"
            component={motion.div}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            direction="row"
            alignItems="baseline"
          >
            <Box sx={{ fontSize: "2rem", fontWeight: 500 }}>$</Box>
            <Box
              sx={{
                fontSize: "3.5rem",
                letterSpacing: "-0.18rem",
                lineHeight: 1.3,
                fontWeight: 600,
              }}
            >{`${props.plan.pricing.yearly.price}`}</Box>
            <Box sx={{ fontSize: "1.2rem" }}>/yr.</Box>
          </Stack>
        )}
      </AnimatePresence>
    </Box>
  );
};

const TierPurchaseButton = (props: { plan: PricingPlan; rate: "monthly" | "yearly" }) => {
  const [{ user }] = useUser();
  const stripeClaim = useStripeClaim();
  const { entitlements } = useEntitlements(user);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { isPremiumStore, isProUser, isPlayUser } = useGlobal();

  return !user ? (
    <Box
      sx={{
        color: "grey.500",
        fontWeight: 300,
        textAlign: "center",
        fontSize: "0.8rem",
        mt: 2,
      }}
    >
      Login to activate subscription
    </Box>
  ) : stripeClaim ? (
    <Box
      component="button"
      onClick={async () => {
        if (!user || !user.uid) return;

        try {
          setIsRedirecting(true);
          const result = await createPortalLink();
          console.log(result);
          if (result) {
            window.location.href = result.data.url;
          }
        } catch (e) {
          setIsRedirecting(false);
        }
      }}
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        fontWeight: 400,
        minWidth: "176px",
        py: 1,
        mt: 2,
        borderRadius: 2,
        "&:disabled": {
          backgroundColor: "grey.300",
          color: "grey.500",
        },
      }}
    >
      {isRedirecting ? (
        <CircularProgress sx={{ color: "white" }} size={20} />
      ) : (
        "Manage Subscription"
      )}
    </Box>
  ) : props.plan.grantsEntitlement &&
    hasPremiumFeature(entitlements, props.plan.grantsEntitlement as PremiumFeature) ? (
    <Box
      sx={{
        color: "grey.500",
        fontWeight: 300,
        textAlign: "center",
        fontSize: "0.8rem",
      }}
    >
      Subscription active through phone app store.
    </Box>
  ) : (
    <Box
      component="button"
      onClick={async () => {
        if (!user || !user.uid) return;
        try {
          setIsRedirecting(true);
          trackEvent("subscription_package_selected", {
            isSubscribed: isPremiumStore || isProUser || isPlayUser,
            selectedPackage: props.plan.label,
            selectedPackageMonths: props.rate === "yearly" ? "annual" : "monthly",
          });
          await createCheckoutSession(user.uid, props.plan.pricing[props.rate].subscriptionId);
        } catch (e) {
          setIsRedirecting(false);
        }
      }}
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        fontWeight: 400,
        minWidth: "176px",
        py: 1,
        borderRadius: 2,
        "&:disabled": {
          backgroundColor: "grey.300",
          color: "grey.500",
        },
      }}
    >
      {isRedirecting ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Subscribe"}
    </Box>
  );
};
