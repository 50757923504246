export const BluetoothServices = {
  Nus: "6e400001-b5a3-f393-e0a9-e50e24dcca9e",
  NusCharacteristicRx: "6e400002-b5a3-f393-e0a9-e50e24dcca9e",
  NusCharacteristicTx: "6e400003-b5a3-f393-e0a9-e50e24dcca9e",
  CyOta: "00060000-f8ce-11e4-abf4-0002a5d5c51b",
  DeviceInfo: 0x180a,
  HardwareRevision: 0x2a27,
  SoftwareRevision: 0x2a28,
  Battery: "battery_service",
  BatteryLevel: "battery_level",
  Calibration: "ae3dc67e-a182-48e1-9b65-8ece7c00f896",
} as const;

export const BluetoothCalibration = {
  Service: "ae3dc67e-a182-48e1-9b65-8ece7c00f896",
  Accel0: "ae3dc67e-a182-48e1-9b65-8ece7c00f897",
  Accel1: "ae3dc67e-a182-48e1-9b65-8ece7c00f898",
  Accel2: "ae3dc67e-a182-48e1-9b65-8ece7c00f899",
  Gyro: "ae3dc67e-a182-48e1-9b65-8ece7c00f89a",
  Quaternion: "ae3dc67e-a182-48e1-9b65-8ece7c00f89b",
  Mac: "ae3dc67e-a182-48e1-9b65-8ece7c00f89c",
  Accel1Float: "ae3dc67e-a182-48e1-9b65-8ece7c00f89d",
  Accel2Float: "ae3dc67e-a182-48e1-9b65-8ece7c00f89e",
  MissedSpi: "ae3dc67e-a182-48e1-9b65-8ece7c00f89f",
  AccelNed: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a0",
  ErrorRx: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a1",
  SampleTime: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a2",
  LoopTime: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a3",
  Button: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a4",
  DebugNumbers: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a5",
  DebugVectors: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a6",
  DeviceUid: "ae3dc67e-a182-48e1-9b65-8ece7c00f8a7",
} as const;

export const CALIBRATION_KEY_NAMES = ["x", "y", "negX", "negY", "z", "negZ"];
export const SENSORS_GRAVITY_STD = 9.80665;
export const SENSORS_DPS_TO_RADS = 0.017453293;

export const DEVICE_PREFIX = "TechDisc";
export const NUM_POINTS = 2000;
export const VERSION_CRC = 3;
export const VERSION_START_Q = 4;
export const VERSION_8K_DPS = 5;
export const VERSION_MAG = 6;
export const MAX_FORMAT_VERSION = VERSION_MAG;
export const MAX_HARDWARE_VERSION = 16;
export const CRC_SKIP_BYTES = 6;
export const TRANSFER_FORMAT_VERSION = 2;
export const DATA_LEN_V5 = 26 * NUM_POINTS + 28; // 52028
export const DATA_LEN_V6 = 53076;
export const DELAY_MS_BEFORE_FETCH_FLIGHT = 1250;
export const DeviceBuffer = new ArrayBuffer(DATA_LEN_V6 + 4);
export const DeviceView = new DataView(DeviceBuffer);
