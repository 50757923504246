import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  TextField,
  Stack,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAllCountryData } from "../../../hooks/useCountryData";
import { getUserId } from "../../../summaryUtils";
import { clearStoreAddress } from "../../../hooks/useStoreMetadata";
import Title from "../../../dashboard/Title";
import FormErrorText from "./FormErrorText";
import { toast } from "sonner";

export default function AddressForm() {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [confirmClearAddressOpen, setConfirmClearAddressOpen] = useState(false);
  const countryData = useAllCountryData();
  const selectedCountryCode = watch("address.country");
  const toggleClearAddress = () => {
    setConfirmClearAddressOpen((open) => !open);
  };
  const handleConfirmClearAddress = async () => {
    await clearStoreAddress(getUserId());
    toggleClearAddress();
    toast.success("Address has been successfully cleared.");
    setValue("address.street", "");
    setValue("address.country", "");
    setValue("address.region", "");
    setValue("address.city", "");
    setValue("address.postalCode", "");
  };

  return (
    <Stack gap={3}>
      <Title variant="secondary">Location</Title>
      <Stack gap={3}>
        <FormControl>
          <FormLabel sx={{ mb: 1 }}>Address</FormLabel>
          <TextField
            size="small"
            inputProps={register("address.street")}
            placeholder="Street address"
          />
          <FormErrorText field="address.street" errors={errors} />
        </FormControl>
        <Stack direction="row" gap={1.5}>
          <Stack flexBasis={"33%"}>
            <FormControl>
              <FormLabel sx={{ mb: 1 }}>Country</FormLabel>
              <Controller
                name="address.country"
                defaultValue={"US"}
                render={({ field }) => (
                  <Select {...field} size="small">
                    {countryData.map((country) => (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormErrorText field="address.country" errors={errors} />
            </FormControl>
          </Stack>
          <Stack flexBasis={"33%"}>
            <FormControl>
              <FormLabel sx={{ mb: 1 }}>State</FormLabel>
              <Controller
                name="address.region"
                render={({ field }) => (
                  <Select {...field} size="small">
                    {countryData
                      ?.find((country) => country.iso2 === selectedCountryCode)
                      ?.states?.map((state) => (
                        <MenuItem key={state.state_code} value={state.state_code}>
                          {state.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              <FormErrorText field="address.region" errors={errors} />
            </FormControl>
          </Stack>
          <Stack flexBasis={"33%"}>
            <FormControl>
              <FormLabel sx={{ mb: 1 }}>City</FormLabel>
              <TextField size="small" inputProps={register("address.city")} />
              <FormErrorText field="address.city" errors={errors} />
            </FormControl>
          </Stack>
          <Stack flexBasis={"33%"}>
            <FormControl>
              <FormLabel sx={{ mb: 1 }}>Zip Code</FormLabel>
              <TextField size="small" inputProps={register("address.postalCode")} />
              <FormErrorText field="address.postalCode" errors={errors} />
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button
          variant="destructive"
          size="small"
          onClick={toggleClearAddress}
          sx={{ width: "fit-content", alignSelf: "flex-end" }}
        >
          Clear Address
        </Button>
      </Stack>
      <Dialog
        open={confirmClearAddressOpen}
        onClose={toggleClearAddress}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Do you want to clear your address?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove your address from your profile. You can add it back later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleClearAddress} variant="secondary">
            Go Back
          </Button>
          <Button onClick={handleConfirmClearAddress} variant="primary" autoFocus>
            Yes, Clear Address
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
