import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export type PricingPlanDocument = {
  label: string;
  includes: string[];
  grantsEntitlement: string;
  pricing: {
    monthly: {
      price: number;
      subscriptionId: string;
    };
    yearly: {
      price: number;
      subscriptionId: string;
    };
  };
  features: string[];
};

export type PricingPlan = PricingPlanDocument & {
  id: string;
};

export const pricingPlanConverter: FirestoreDataConverter<PricingPlan, PricingPlanDocument> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<PricingPlanDocument>,
    options: SnapshotOptions,
  ) => {
    const data = snapshot.data(options);

    return { ...data, id: snapshot.id } as PricingPlan; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: PricingPlan) => {
    const { id, ...documentData } = modelObject;
    return { ...documentData };
  },
};
