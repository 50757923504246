import { itemVariants } from "@/components/routes/variants";
import type { StrokePlayLeaderboardEntry } from "@/model/leaderboard";
import type { SimulatorLayout } from "@/types/simulator";
import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function PlayLayouts({
  layouts,
}: {
  layouts?: (SimulatorLayout & { leaderboard?: StrokePlayLeaderboardEntry[] })[];
}) {
  const navigate = useNavigate();

  return layouts?.map((layout) => (
    <Box
      key={layout?.id}
      component={motion.div}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 1 }}
      variants={itemVariants}
      sx={{
        borderRadius: 4,
        background: `url("${layout?.assets?.patternUrl}")`,
        backgroundColor: "#1c2833",
        minWidth: { desktop: "512px", laptop: "384px", mobile: "256px" },
        maxWidth: { desktop: "512px", laptop: "384px", mobile: "256px" },
      }}
    >
      <Stack
        component={motion.button}
        onClick={() => navigate(`/play/leaderboards/${layout?.id}`)}
        sx={{
          aspectRatio: 1,
          userSelect: "none",
          width: "100%",
          textAlign: "center",
          alignItems: "center",
          color: "white",
          background: `url("${layout?.assets?.playImageUrl}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "content-box",
          backgroundPosition: "50% 400%",
          p: 4,
          fontWeight: 600,
          borderRadius: 4,
          letterSpacing: "-0.1rem",
          lineHeight: 1.1,
        }}
      >
        <Box
          component={motion.span}
          layout="position"
          layoutId={`layout-name-${layout?.id}`}
          sx={{
            fontWeight: 600,
            filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.8))",
            fontSize: { laptop: "2.3rem", tablet: "1.8rem", mobile: "1.5rem" },
          }}
        >
          {layout?.name}
        </Box>
      </Stack>
    </Box>
  ));
}
