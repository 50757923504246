import { getLiveEventsCollection } from "@/firebase/collections";
import { isAfter, isBefore } from "date-fns";
import { getDocs, orderBy, query } from "firebase/firestore";
import LogRocket from "logrocket";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter, json } from "react-router-dom";
import Layout from "./components/layout/Layout";
import NotFound from "./components/layout/NotFound";
import AppProviders from "./components/providers/Providers";
import { setupFirebase } from "./firebaseConfig";
import {
  adminRoutes,
  dashboardRoutes,
  deviceRoutes,
  discRoutes,
  legalRoutes,
  liveRoutes,
  redirectRoutes,
  settingsRoutes,
  shareRoutes,
  simulatorRoutes,
  stockShotRoutes,
  storeRoutes,
  throwRoutes,
  vanityRoutes,
  videoRoutes,
} from "./routes";

LogRocket.init("bvoaxx/unity-debug");
setupFirebase();

const App = () => {
  const router = createBrowserRouter(
    [
      {
        element: (
          <AppProviders>
            <Layout />
          </AppProviders>
        ),
        id: "root",
        loader: async () => {
          const docs = await getDocs(
            query(getLiveEventsCollection(), orderBy("startDate", "desc")),
          );
          const events = docs.docs.map((doc) => doc.data());
          const liveEvent = events.find(
            (event) =>
              isBefore(new Date(), event.endDate?.toDate()) &&
              isAfter(new Date(), event.startDate?.toDate()),
          );

          return json({
            activeEvent: liveEvent
              ? {
                  ...liveEvent,
                  startDate: liveEvent?.startDate?.toDate().toISOString(),
                  endDate: liveEvent?.endDate?.toDate().toISOString(),
                }
              : null,
          });
        },
        children: [
          ...videoRoutes,
          ...shareRoutes,
          ...throwRoutes,
          ...videoRoutes,
          ...redirectRoutes,
          ...adminRoutes,
          ...storeRoutes,
          ...liveRoutes,
          ...discRoutes,
          ...settingsRoutes,
          ...legalRoutes,
          ...deviceRoutes,
          ...simulatorRoutes,
          ...stockShotRoutes,
          ...dashboardRoutes,
          ...vanityRoutes,
          { path: "*", element: <NotFound /> },
        ],
      },
    ],
    {
      future: {
        // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
        v7_normalizeFormMethod: true,
      },
    },
  );

  return <RouterProvider router={router} fallbackElement={null} />;
};

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
