import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";
import { BluetoothStatus } from "./useBluetooth";

export const [bluetoothDeviceChanged$, setBluetoothDevice] = createSignal<BluetoothDevice | null>();
export const [useBluetoothDevice, bluetoothDevice$] = bind(bluetoothDeviceChanged$, null);

export const [bluetoothCharacteristicsChanged$, setBluetoothCharacteristics] = createSignal<Map<
  string,
  BluetoothRemoteGATTCharacteristic
> | null>();
export const [useBluetoothCharacteristics, bluetoothCharacteristics$] = bind(
  bluetoothCharacteristicsChanged$,
  null,
);

export const [deviceStatusChanged$, setDeviceStatus] = createSignal<BluetoothStatus>();
export const [useDeviceStatus, deviceStatus$] = bind(deviceStatusChanged$, BluetoothStatus.Idle);
