import { Box, Container, Stack } from "@mui/material";
import { PricingTable } from "../PricingTable";
import type { USER_ROLES } from "../user/consts";
import { useEffect } from "react";
import { trackEvent } from "@/utils/logging";
import { useGlobal } from "../providers/GlobalProvider";

type UnauthorizedProps = {
  requiredRole?: string;
};

export default function Unauthorized(props: UnauthorizedProps) {
  const { isPremiumStore, isProUser, isPlayUser } = useGlobal();

  useEffect(() => {
    if (props.requiredRole) {
      trackEvent("subscription_paywall_appeared", {
        requiredRole: props.requiredRole,
        isSubscribed: isPremiumStore || isProUser || isPlayUser,
      });
    }
  }, [props.requiredRole]);

  return (
    <Container sx={{ pt: 4, gap: 5, display: "flex", flexDirection: "column" }}>
      <PricingTable
        header={
          <Stack gap={2} alignItems="center">
            <Box
              sx={{
                fontSize: "2rem",
                fontWeight: 300,
                letterSpacing: "-0.1rem",
                maxWidth: "600px",
                textAlign: "center",
              }}
            >
              Start your{" "}
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontSize: "2rem",
                  fontWeight: 500,
                  letterSpacing: "-0.1rem",
                }}
              >
                {getRoleName(props.requiredRole as keyof typeof USER_ROLES)}
              </Box>{" "}
              today to access this feature.
            </Box>
          </Stack>
        }
      />
    </Container>
  );
}

function getRoleName(role: keyof typeof USER_ROLES) {
  switch (role) {
    case "PRO_USER":
      return "Premium subscription";
    case "PLAY_USER":
      return "Play subscription";
    case "PREMIUM_STORE":
      return "Premium subscription";
    default:
      return "premium members";
  }
}
