import type { ThrowSummary } from "@/model/throwSummary";
import { setDoc } from "firebase/firestore";
import { throwSummaryConverter } from "../converters/throwSummary";
import { getThrowSummary } from "../docs";

export const updateThrowSummary = async (
  userId: string,
  throwId: string,
  updatedData: Partial<ThrowSummary>,
): Promise<void> => {
  const summaryRef = getThrowSummary(userId, throwId)?.withConverter(throwSummaryConverter);

  if (summaryRef) {
    await setDoc(summaryRef, updatedData, { merge: true });
  }
};
