import { CssBaseline, ThemeProvider, createTheme, lighten, styled } from "@mui/material";
import { ReactNode, StrictMode } from "react";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import theme from "../../theme";
import GlobalProvider from "./GlobalProvider";
import i18n from "../../i18next";
import { I18nextProvider } from "react-i18next";
import SplitProvider from "./SplitProvider";

const muiTheme = createTheme(theme);

function AppProviders(props: { children: ReactNode }) {
  return (
    <StrictMode>
      <I18nextProvider i18n={i18n} defaultNS={"common"}>
        <SplitProvider>
          <GlobalProvider>
            <ThemeProvider theme={muiTheme}>
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: { autoFocus: true },
                }}
              >
                {props.children}
              </ConfirmProvider>
            </ThemeProvider>
          </GlobalProvider>
        </SplitProvider>
      </I18nextProvider>
    </StrictMode>
  );
}

export default AppProviders;
