import { useUser } from "@/hooks/useUser";
import { useEntitlements } from "@/stripe";

import type { SimulatorCourseHole } from "@/types/simulator";
import { getUserPermissions } from "@/utils/auth";
import { useEffect, useState } from "react";

export const useSimulatorPermissions = () => {
  const [loading, setLoading] = useState(true);
  const [allowedCourses, setAllowedCourses] = useState<
    { id?: string; displayName?: string; holes?: SimulatorCourseHole[] }[] | null | undefined
  >(null);
  const [allowedLayouts, setAllowedLayouts] = useState<
    { id?: string; displayName?: string }[] | null | undefined
  >(null);
  const [{ user, trueUserId, userLoading }] = useUser();

  const { entitlements, loading: entitlementsLoading } = useEntitlements(user, userLoading);

  useEffect(() => {
    if (userLoading || entitlementsLoading) {
      return;
    }
    async function getPermissions() {
      try {
        const permissions = await getUserPermissions(trueUserId, entitlements);
        setAllowedCourses(permissions?.allowedCourses);
        setAllowedLayouts(permissions?.allowedLayouts);
      } finally {
        setLoading(false);
      }
    }
    getPermissions();
  }, [
    trueUserId,
    entitlements,
    userLoading,
    entitlementsLoading,
    setAllowedCourses,
    setAllowedLayouts,
    setLoading,
  ]);

  return [{ allowedCourses, allowedLayouts }, loading] as const;
};
