import { BluetoothCalibration } from "./consts";
import { DeviceInfo } from "./types";

// handleErrorLogUpdate function
export const handleErrorLogUpdate = async (value: DataView) => {
  const uint8Array = new Uint8Array(value.buffer, value.byteOffset, value.byteLength);
  const textDecoder = new TextDecoder();
  const decodedString = textDecoder.decode(uint8Array);

  // DataView is a C style string terminated by a null character
  const string = decodedString.split("\x00")[0];
  if (string.length === 0) {
    return;
  }

  console.error("ERROR LOG FROM TECHDISC: " + string);
};

// downloadBlob function
export const downloadBlob = (fileName: string, buf: ArrayBuffer) => {
  const a = document.createElement("a");
  const file = new Blob([buf], { type: "application/octet-stream" });
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, 0);
};

// downloadFile function
export const downloadFile = (suffix: string, buf: ArrayBuffer) => {
  const fileName = "throw_" + Date.now() + "." + suffix;
  downloadBlob(fileName, buf);
};

// getDeviceId function, requires bleDevice and deviceId as parameters
export const getDeviceId = async (
  device: BluetoothDevice,
  macChar: BluetoothRemoteGATTCharacteristic,
) => {
  const macAddress = await macChar.readValue();
  const deviceId = Array(6)
    .fill(0)
    .map((_, i) => {
      const byte = macAddress.getUint8(i);
      return ("0" + (byte & 0xff).toString(16)).slice(-2);
    })
    .join(":");

  if (!deviceId) {
    let id = device?.id;
    if (!id) return;
    id = id.replace(/\//g, "_");
    id = id.replace(/\+/g, "-");
    return id.replace(/=/g, "");
  }

  return deviceId;
};

export const getDeviceUid = async (uidChar: BluetoothRemoteGATTCharacteristic) => {
  const uid = await uidChar.readValue();
  const deviceUid = Array(8)
    .fill(0)
    .map((_, i) => {
      const byte = uid.getUint8(i);
      return ("0" + (byte & 0xff).toString(16)).slice(-2);
    })
    .join(":");

  return deviceUid;
};

export const downloadFlight = async (
  flightDownloading: NodeJS.Timeout | null,
  sendMessage: (message: string) => Promise<void>,
) => {
  if (flightDownloading) {
    clearTimeout(flightDownloading);
    flightDownloading = null;
  }

  flightDownloading = setTimeout(() => {
    if (flightDownloading) {
      clearTimeout(flightDownloading);
      flightDownloading = null;
    }
  }, 30 * 1000);
  await sendMessage("flight");
};

export const sendNusData = async (
  device: BluetoothDevice,
  rxCharacteristic: BluetoothRemoteGATTCharacteristic,
  message: string,
) => {
  if (device && device.gatt?.connected) {
    console.log("send: " + message);
    const val_arr = new Uint8Array(message.length);
    for (let i = 0; i < message.length; i++) {
      val_arr[i] = message[i].charCodeAt(0);
    }
    try {
      await rxCharacteristic.writeValue(val_arr);
    } catch (error) {
      console.log(error);
      try {
        await new Promise((resolve) => setTimeout(resolve, 60));
        await rxCharacteristic.writeValue(val_arr);
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    console.log("Not connected to a device yet.");
  }
};

export const getDeviceInfo = async (
  macCharacteristic?: BluetoothRemoteGATTCharacteristic,
  deviceUidCharacteristic?: BluetoothRemoteGATTCharacteristic,
) => {
  const device = { deviceId: "", deviceUid: "" };
  console.group("Device Information");
  try {
    if (macCharacteristic) {
      const macAddress = await macCharacteristic.readValue();
      device.deviceId = Array(6)
        .fill(0)
        .map((_, i) => {
          const byte = macAddress.getUint8(i);
          return ("0" + (byte & 0xff).toString(16)).slice(-2);
        })
        .join(":");
      console.log("ID:", device.deviceId);
    }
    if (deviceUidCharacteristic) {
      const uid = await deviceUidCharacteristic.readValue();
      device.deviceUid = Array(8)
        .fill(0)
        .map((_, i) => {
          const byte = uid.getUint8(i);
          return ("0" + (byte & 0xff).toString(16)).slice(-2);
        })
        .join(":");
      console.log("UID:", device.deviceUid);
    }
    console.groupEnd();

    return device;
  } catch (error) {
    console.log(
      "old devices do not support ids across devices, please update to the lastest firmware",
    );
    console.error(error);
    return { deviceId: null, deviceUid: null };
  }
};

export function timeout<T>(promise: Promise<T>, { ms }: { ms: number }): Promise<T> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error(`Operation timed out after ${ms} ms`));
    }, ms);

    promise
      .then((value) => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch((error) => {
        clearTimeout(timer);
        reject(error);
      });
  });
}
