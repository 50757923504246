import {
  useVideoDevice,
  type VideoDeviceData,
} from "@/components/routes/video/VideoDeviceProvider";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { VideoCameraFront } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { VideoPlayer } from "@tdisc/video";
import { useState } from "react";
import { SelectVideoDevice } from "./SelectVideoDevice";

// const testConstraint = async (stream: MediaStream, constraint: MediaTrackConstraints) => {
//   const videoTrack = stream.getVideoTracks()[0];
//   try {
//     await videoTrack.applyConstraints(constraint);
//     console.log(stream);
//     console.log("Applied constraints", constraint);
//     return true;
//   } catch (error) {
//     console.error(error, constraint);
//     return false;
//   }
// };

// const createConstraint = (frameRate: number, resolution: string) => {
//   return {
//     frameRate: { min: 30, ideal: frameRate },
//     width: { min: 1280, ideal: 1920 },
//     height: { min: 720, ideal: 1080 },
//     advanced: [{ width: 1920, height: 1080 }, { aspectRatio: 1.777777 }],
//   };
// };
export const VideoSetup = () => {
  const { devices, handleConnect, handleActiveDeviceChange } = useVideoDevice();
  const [selectedDevice, setSelectedDevice] = useState<VideoDeviceData | undefined>(undefined);
  const [primaryDeviceInfo, setPrimaryDeviceInfo] = useLocalStorage(
    "primaryDevice",
    JSON.stringify({}),
  );
  const [connecting, setConnecting] = useState(false);
  const handleDeviceChange = async (device: MediaDeviceInfo) => {
    const result = await handleConnect(device.deviceId);
    if (result) {
      setSelectedDevice({ id: device.deviceId, stream: result.stream, info: device });
    }
    return { id: device.deviceId, stream: result.stream, info: device };
  };
  const primaryDevice = JSON.parse(primaryDeviceInfo);

  const [open, setOpen] = useState(() => (primaryDevice?.deviceId ? true : false));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack gap={4} sx={{ width: "100%" }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{ fontSize: "2rem", letterSpacing: "-0.1rem", fontWeight: 500, color: "grey.600" }}
          >
            Video Setup
          </Box>
          <Box
            sx={{
              boxSizing: "content-box",
              backgroundColor: "#ede9fe",
              border: "1px solid #a5b4fc",
              color: "#4338ca",
              fontWeight: 500,
              letterSpacing: "-0.05rem",
              borderRadius: "4px",
              fontSize: "1rem",
              padding: "2px 4px",
            }}
          >
            BETA
          </Box>
        </Stack>
        <Card sx={{ position: "relative" }}>
          <CardContent>
            <Stack gap={8} direction="row" justifyContent="center" sx={{ my: 2, width: "100%" }}>
              <Stack alignItems="center" direction="row" gap={4}>
                <Box
                  sx={{
                    lineHeight: "64px",
                    textAlign: "center",
                    width: 64,
                    height: 64,
                    borderRadius: "50%",
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                    fontSize: "1.6rem",
                    fontWeight: 400,
                  }}
                >
                  1
                </Box>
                <SelectVideoDevice
                  selectedDevice={selectedDevice?.info}
                  onDeviceChange={handleDeviceChange}
                  devices={devices}
                />
              </Stack>
              <Stack alignItems="center" direction="row" gap={4}>
                <Box
                  sx={{
                    lineHeight: "64px",
                    textAlign: "center",
                    width: 64,
                    height: 64,
                    borderRadius: "50%",
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                    fontSize: "1.6rem",
                    fontWeight: 400,
                  }}
                >
                  2
                </Box>
                <FormControl>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={!selectedDevice}
                    onClick={async () => {
                      if (!selectedDevice) return;
                      await handleActiveDeviceChange(selectedDevice);
                      setPrimaryDeviceInfo(JSON.stringify(selectedDevice.info));
                    }}
                  >
                    Start Session
                  </Button>
                </FormControl>
              </Stack>
            </Stack>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 4,
                "& *:first-child": { gridColumn: "span 2" },
              }}
            >
              <VideoPlayer autoPlay stream={selectedDevice?.stream} id={"setup-video"} />
            </Box>
          </CardContent>
        </Card>
      </Stack>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Camera Found"}</DialogTitle>
        <Stack component={DialogContent} gap={2}>
          <DialogContentText id="alert-dialog-description">
            Would you like to reconnect?
          </DialogContentText>
          <Stack alignItems="center" direction="row" gap={1}>
            <VideoCameraFront sx={{ fontSize: "1.6rem" }} />
            <Box sx={{ fontSize: "1.4rem" }}>{primaryDevice?.label}</Box>
          </Stack>
        </Stack>
        <DialogActions>
          <Button
            disabled={connecting}
            variant="secondary"
            onClick={handleClose}
            sx={{ minWidth: "100px" }}
          >
            Cancel
          </Button>
          <Button
            disabled={connecting}
            variant="primary"
            onClick={async () => {
              setConnecting(true);
              if (primaryDevice) {
                await handleActiveDeviceChange(await handleDeviceChange(primaryDevice));
              }
              handleClose();
              setConnecting(false);
            }}
            autoFocus
            sx={{ minWidth: "100px" }}
          >
            {connecting ? <CircularProgress sx={{ color: "white" }} size={24} /> : "Reconnect"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
