import { useCallback } from "react";
import { useMatches, useParams } from "react-router-dom";
import { BleConnection, BleConnectionProps } from "../../bleConnection";

import { useUser } from "../../hooks/useUser";
import { useGlobal } from "../providers/GlobalProvider";
export type NavbarProps = {
  latestThrowId?: string;
} & Partial<Omit<BleConnectionProps, "getLatestThrowId" | "getLockedTags">>;

export default function Navbar(props: NavbarProps) {
  const { latestThrowId } = props;
  const [{ user }] = useUser();
  const { isManufacturing } = useGlobal();
  const [_, ...routes] = useMatches();
  const { throwId, throwSetId } = useParams();
  const getLatestThrowId = useCallback(() => latestThrowId, [latestThrowId]);

  const showConnectButton = routes.some((route) =>
    ["/", "/test-drive", "/device", "/stock-shots", "/video/capture", "/play", "/preview"].includes(
      route.pathname,
    ),
  );

  const shareEnabled = routes.some((route) =>
    ["/", `/throws/${throwId}`, `/throws/sets/${throwSetId}`].includes(route.pathname),
  );

  const isDeviceBuild = routes.some((route) => route.pathname === "/device");

  return (
    <BleConnection
      isDeviceBuild={isDeviceBuild}
      isManufacturing={isManufacturing}
      hideConnectButton={!showConnectButton}
      getLatestThrowId={getLatestThrowId}
      shareEnabled={Boolean(user) && shareEnabled}
    />
  );
}
