import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  limit as limitQuery,
  orderBy,
  query,
  QueryConstraint,
  Timestamp,
  where,
} from "firebase/firestore";
import { getUserDoc } from "../summaryUtils";
import { ThrowSummary } from "../model/throwSummary";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import { firebaseApp } from "../firebaseConfig";
import { ThrowSummaryAndId } from "../latestDashboard";
// import { TableControls } from "./TableControls";
import { DateRange } from "rsuite/DateRangePicker";
import { TableStats } from "./TableStats";
import { TableTags } from "./TableTags";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ThrowTableInternal } from "./ThrowTableInternal";
import UserSettings from "../model/UserSettings";
import ThrowsToolbar from "./ThrowsToolbar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Stack, Tab } from "@mui/material";
import { TimelineChart } from "./charts/TimelineChart";
import { FrequencyChart } from "./charts/FrequencyChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { useGlobal } from "../components/providers/GlobalProvider";
import TagManager from "../components/TagManager";
import { FilterPropertyType, typeKeys } from "./shared/utility";
import { getUserThrowSummaries } from "../firebase/collections";
import RouteLayout from "../components/layout/RouteLayout";
import { containerVariants, itemVariants } from "../components/routes/variants";
import Section from "../components/Section";

export type FilterType = {
  property: FilterPropertyType;
  min: number;
  bin: number | string;
  max: number;
};

export type TableRenderProps = {
  docs: ThrowSummary[];
  filters: FilterType[];
  setLimit: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<FilterType[]>>;
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  uid: string;
  limit: number;
  isLoading?: boolean;
  userSettings?: UserSettings;
};

export type TableSearchProps = TableRenderProps & {
  docs: ThrowSummaryAndId[];
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  dateRange: DateRange;
  isLoading: boolean;
  uid: string;
  primaryTypes: string[];
  secondaryTypes: string[];
  setPrimaryTypes: Dispatch<SetStateAction<string[]>>;
  setSecondaryTypes: Dispatch<SetStateAction<string[]>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  hasNotes: boolean;
  setHasNotes: Dispatch<SetStateAction<boolean>>;
  showHidden: boolean;
  setShowHidden: Dispatch<SetStateAction<boolean>>;
  suggestedTags: string[];
  defaultTags: string[];
  setDefaultTags: Dispatch<SetStateAction<string[]>>;
  userSettings: UserSettings;
};

export interface ThrowTableProps {
  userId: string;
  dateRange: DateRange;
  queryLimit?: number;
}

export function ThrowTable(props: ThrowTableProps) {
  const { userId, dateRange: defaultRange, queryLimit: defaultLimit = 250 } = props;
  const { prefersMetric } = useGlobal();
  const [tab, setTab] = useState("table");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const [dateRange, setDateRange] = useState<DateRange>(defaultRange);
  const [primaryTypes, setPrimaryTypes] = useState<string[]>([]);
  const [secondaryTypes, setSecondaryTypes] = useState<string[]>([]);
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [limit, setLimit] = useState(defaultLimit);
  const [defaultTags, setDefaultTags] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [showHidden, setShowHidden] = useState<boolean>(false);
  const [hasNotes, setHasNotes] = useState<boolean>(false);

  const [userSettingsDoc] = useDocument(getUserDoc(userId));
  const userSettings = (userSettingsDoc?.data() as UserSettings) ?? {};

  const q = useMemo(() => {
    const q1 = getUserThrowSummaries(userId);

    const filters: QueryConstraint[] = [];

    if (primaryTypes.length > 0) {
      if (primaryTypes.length === 1) {
        filters.push(where("primaryType", "==", primaryTypes[0]));
      } else if (secondaryTypes.length === 0) {
        filters.push(where("primaryType", "in", primaryTypes));
      }
    }

    if (secondaryTypes.length > 0) {
      filters.push(where("secondaryType", "in", secondaryTypes));
    }

    if (defaultTags.length > 0) {
      filters.push(where("tags", "array-contains-any", defaultTags));
    }

    if (hasNotes) {
      filters.push(where("hasNotes", "==", true));
    }

    if (showHidden) {
      filters.push(where("hidden", "in", ["true", "play"]));
    } else {
      filters.push(where("hidden", "in", ["false"]));
    }

    filters.push(where("throwTime", ">=", Timestamp.fromMillis(dateRange[0].getTime())));
    filters.push(where("throwTime", "<=", Timestamp.fromMillis(dateRange[1].getTime())));

    filters.push(limitQuery(limit + 1)); // adding extra to check for if limit hit
    filters.push(orderBy("throwTime", "desc"));

    logEvent(getAnalytics(firebaseApp), "table_query", { limit });

    return query(q1, ...filters);
  }, [userId, limit, primaryTypes, secondaryTypes, hasNotes, defaultTags, showHidden, dateRange]);

  const [throwSummaries, isLoading, error] = useCollectionData<ThrowSummary>(q);

  const filtered = (throwSummaries ?? []).filter((v) => !v.loading);
  console.log(throwSummaries);
  const tableProps: TableSearchProps = {
    docs: filtered,
    userSettings,
    setDateRange,
    primaryTypes,
    setPrimaryTypes,
    secondaryTypes,
    setSecondaryTypes,
    dateRange,
    filters,
    setFilters,
    limit,
    defaultTags,
    suggestedTags: TagManager.getSuggestedTags(userId),
    setDefaultTags,
    setLimit,
    tags,
    setTags,
    showHidden,
    setShowHidden,
    uid: userId,
    isLoading: isLoading,
    hasNotes,
    setHasNotes,
  };

  return (
    <RouteLayout containerVariants={containerVariants}>
      <Container
        sx={{
          mt: { mobile: 2, md: 4 },
          mb: 4,
          display: "flex",
          flexDirection: "column",
          gap: { mobile: 1.5, md: 2 },
        }}
      >
        <Section itemVariants={itemVariants}>
          <TableStats {...tableProps} />
        </Section>
        <Section itemVariants={itemVariants}>
          <ThrowsToolbar {...tableProps} />
        </Section>
        <Section itemVariants={itemVariants}>
          <TableTags {...tableProps} />
        </Section>
        <Section itemVariants={itemVariants}>
          <Stack gap={1}>
            <TabContext value={tab}>
              <Stack direction="row" spacing={0} justifyContent="center">
                <TabList
                  // variant="standard"
                  onChange={handleTabChange}
                  TabIndicatorProps={{ sx: { display: "none" } }}
                >
                  <Tab label={<TableRowsIcon fontSize="small" />} value="table" />
                  <Tab label={<BarChartIcon fontSize="small" />} value="barchart" />
                  {/* <Tab label={<ScatterPlotIcon fontSize="small" />} value="scatterchart" /> */}
                </TabList>
              </Stack>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TabPanel sx={{ m: 0, p: 0 }} value="table">
                  <ThrowTableInternal userId={userId} {...tableProps} />
                </TabPanel>
                <TabPanel sx={{ m: 0, p: 0 }} value="barchart">
                  <Grid
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xl: "repeat(2, 1fr)",
                        lg: "repeat(2, 1fr)",
                        md: "repeat(2, 1fr)",
                        sm: "repeat(1, 1fr)",
                        mobile: "repeat(1, 1fr)",
                      },
                    }}
                  >
                    {[
                      "throwTime",
                      prefersMetric ? "speedKmh" : "speedMph",
                      prefersMetric ? "distanceMeters" : "distanceFeet",
                      "spinRpm",
                      "hyzerAngle",
                      "noseAngle",
                      "wobble",
                      "launchAngle",
                    ].map((item: string, index) => (
                      <Stack key={index} sx={{ m: 2 }} spacing={2} flexBasis={"50%"}>
                        <FrequencyChart {...tableProps} type={item as FilterPropertyType} />
                      </Stack>
                    ))}
                  </Grid>
                </TabPanel>
                <TabPanel sx={{ m: 0, p: 0 }} value="scatterchart">
                  <Grid
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xl: "repeat(1, 1fr)",
                        lg: "repeat(1, 1fr)",
                        md: "repeat(1, 1fr)",
                        sm: "repeat(1, 1fr)",
                        mobile: "repeat(1, 1fr)",
                      },
                    }}
                  >
                    {[...Object.keys(typeKeys), "estimatedFeet"].map((item: string, index) => (
                      <Stack key={index} sx={{ m: 2 }} spacing={2} flexBasis={"50%"}>
                        <TimelineChart {...tableProps} type={item as FilterPropertyType} />
                      </Stack>
                    ))}
                  </Grid>
                </TabPanel>
              </Paper>
            </TabContext>
          </Stack>
        </Section>
      </Container>
    </RouteLayout>
  );
}
