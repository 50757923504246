import { useSimulatorPermissions } from "@/hooks/useSimulatorPermissions";
import { Box, Stack } from "@mui/system";
import { getAnalytics, logEvent } from "firebase/analytics";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useState } from "react";
import Flight3D from "../dashboard/Flight3D";
import { firebaseApp } from "../firebaseConfig";
import { ThrowSummaryAndId } from "../latestDashboard";
import { ThrowSummary } from "../model/throwSummary";
import { UnityComponent } from "./flight/UnityComponent";
import { UnityContextHook } from "react-unity-webgl/distribution/types/unity-context-hook";

class ErrorBoundary extends React.Component<
  { flights?: ThrowSummaryAndId[]; children: ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logEvent(getAnalytics(firebaseApp), "simulator_error", {
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.flights) {
        return <Flight3D flights={this.props.flights} />;
      }
      //   return <Simulate3D flightParams={this.props.flights} isFlightPathMode={true} />;
    }

    return this.props.children;
  }
}

// single is for one throw, multiple is for many throws
export type SimulatorMode = "simulated" | "single" | "multiple";

interface SimulatorProps {
  context: UnityContextHook;
  mode: SimulatorMode;
  flights?: ThrowSummary[];
  fallback?: JSX.Element;
  userId: string;
  backgroundImage?: string;
  strokePlayBadge?: string;
}

function Simulator(props: SimulatorProps) {
  const {
    strokePlayBadge,
    context,
    flights,
    userId,
    mode = "single",
    backgroundImage = "https://storage.googleapis.com/techdisc-cdn/UnityLoading.png",
  } = props;
  const [permissions, loading] = useSimulatorPermissions();
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const hideLoadingScreen = () => setShowLoadingScreen(false);
  return (
    <Stack
      gap={1}
      sx={{
        outline: "0px",
        width: "100%",
        aspectRatio: 16 / 9,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <AnimatePresence mode="wait">
        {(loading || showLoadingScreen) && (
          <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="loading-screen"
            transition={{ duration: 1.5, ease: "easeIn" }}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              aspectRatio: 16 / 9,
              background: `url("${backgroundImage}")`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="popLayout">
        {flights && flights?.length > 0 ? (
          <ErrorBoundary flights={flights}>
            <UnityComponent
              strokePlayBadge={strokePlayBadge}
              unityContext={context}
              mode={mode}
              recentThrows={flights}
              summary={flights[0]}
              userId={userId}
              permissions={permissions}
              permissionsLoading={loading}
              onInit={() => {
                hideLoadingScreen();
              }}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <UnityComponent
              strokePlayBadge={strokePlayBadge}
              unityContext={context}
              mode={mode}
              userId={userId}
              permissions={permissions}
              permissionsLoading={loading}
              onInit={() => {
                hideLoadingScreen();
              }}
            />
          </ErrorBoundary>
        )}
      </AnimatePresence>
    </Stack>
  );
}

export default Simulator;
