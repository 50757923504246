// Device detection utility
import { config, mobileConfig } from "@/simulator/utils";
import { Unity, useUnityContext as useUnityWebGLContext } from "react-unity-webgl";

const getDeviceConfig = (buildNumber?: string) => {
  const deviceType = detectDevice();
  if (deviceType === "phone" || deviceType === "tablet") {
    return mobileConfig(buildNumber);
  }
  return config(buildNumber);
};

function detectDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /ipad|android.*tablet|tablet.*android/i.test(userAgent);
  const isMobile = /iphone|android.*mobile|mobile.*android/i.test(userAgent);

  if (isTablet) {
    return "tablet";
  } else if (isMobile) {
    return "phone";
  } else {
    return "desktop";
  }
}

export const useUnityContext = ({ buildNumber }: { buildNumber?: string }) => {
  return useUnityWebGLContext(getDeviceConfig(buildNumber));
};
