import { Box, Button, Stack, Typography } from "@mui/material";
import { CSSProperties, useEffect } from "react";
import { BuildDevice } from "../model/device";
import { BuildProps } from "./BuildDashboard";

import {
  AccelCalibrateKey,
  CalibrationValues,
  DeviceReadings,
  Vector3,
  Vector6,
} from "../model/calibrate";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "../dashboard/Title";
import { format } from "date-fns";
import { isCalibrationReady } from "../bleConsts";
import { toast } from "sonner";

function getIndexForKey(key: AccelCalibrateKey) {
  return key === "x" || key === "negX" ? 0 : key === "y" || key === "negY" ? 1 : 2;
}

function createCalibrateButton(
  key: AccelCalibrateKey,
  props: BuildProps,
  sensor: keyof DeviceReadings,
) {
  const lastReading = props.lastReading?.accel0;
  const isLockedIn = !!props.readings?.[sensor][key];
  const index = getIndexForKey(key);

  let text: string = key;
  if (isLockedIn) {
    const lockedReading = props.readings?.[sensor][key] as Vector3;
    const value = lockedReading[index];
    text = text.replace("neg", "-");
    text = text + ": " + String(value.toFixed(3));
  }

  let color: "primary" | "success" | "warning" = "primary";
  if (lastReading) {
    const vector = [...lastReading];
    const reverse = key.startsWith("neg");
    const value = lastReading[index] * (reverse ? -1 : 1);

    // remove index from vector
    vector.splice(index, 1);

    const valueClose = Math.abs(value - 9.81) < 1.0;

    if (valueClose && Math.abs(vector[0]) < 0.5 && Math.abs(vector[1]) < 0.5) {
      color = "success";
    } else if (valueClose) {
      color = "warning";
    }
  }

  return (
    <Button
      disabled={isLockedIn}
      variant={"contained"}
      id={"calibration_" + key}
      color={color}
      key={key}
      onClick={() => {
        if (color === "success") {
          props.calibrateDirection(key);
        }
      }}
    >
      {text}
    </Button>
  );
}

const boxStyle: CSSProperties = {
  display: "inline-block",
  padding: "10px 20px", // Adjust padding to achieve desired size
  width: "130px",
  fontSize: "16px", // Adjust font size
  fontWeight: "bold",
  textAlign: "center",
  border: "1px solid #ccc",
  borderRadius: "5px", // Adjust border radius for rounded corners
  backgroundColor: "#f0f0f0",
  cursor: "pointer",
};

function NumberBox(props: { value: number; floatRight?: boolean }) {
  const prefix = props.value >= 0 ? "+" : "";
  const style: CSSProperties = { ...boxStyle };
  if (props.floatRight) {
    style.marginRight = "auto";
  }
  return <div style={style}>{prefix + props.value.toFixed(3)}</div>;
}

function getColorForCalibration(sensorKey: keyof CalibrationValues, scale: number, offset: number) {
  let maxOffset = 0.0;
  let maxScaleDiff = 0.0;
  if (sensorKey === "accel0") {
    maxOffset = 0.13;
    maxScaleDiff = 0.01;
  } else if (sensorKey === "mag") {
    maxOffset = 0.5;
    maxScaleDiff = 0.3;
  } else if (sensorKey === "gyro") {
    maxOffset = 0.02;
  } else if (sensorKey === "accel1" || sensorKey == "accel2") {
    maxOffset = 13;
    maxScaleDiff = 0.05;
  }

  if (Math.abs(offset) > 2 * maxOffset || Math.abs(scale - 1.0) > 2 * maxScaleDiff) {
    return "#E00000";
  }
  if (Math.abs(offset) > maxOffset || Math.abs(scale - 1.0) > maxScaleDiff) {
    return "#C0C000";
  }
  return "#000000";
}

function MathBox(props: {
  value: Vector6;
  dataKey: "x" | "y" | "z";
  sensorKey: keyof CalibrationValues;
}) {
  const key = props.dataKey;
  const style: CSSProperties = { ...boxStyle };
  style.width = "250px";

  const index = getIndexForKey(key);
  const scale = props.value[index];
  const offset = props.value[index + 3];
  const color = getColorForCalibration(props.sensorKey, scale, offset);
  style.color = color;
  return <div style={style}>{scale.toFixed(3) + " * " + key + " + " + offset.toFixed(3)}</div>;
}

export default NumberBox;

function createCalibrateButtons(props: BuildProps, sensor?: keyof DeviceReadings) {
  const disableCal = !isCalibrationReady(props.readings);
  const sensorKey = sensor ?? "accel0";
  const accel0 = props.lastReading?.[sensorKey];
  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        p: 2,
      }}
    >
      <Stack spacing={2} direction="row">
        {createCalibrateButton("x", props, sensorKey)}
        {createCalibrateButton("negX", props, sensorKey)}
        {accel0 && props.calibrating && <NumberBox value={accel0[0]} />}
      </Stack>
      <Stack spacing={2} direction="row">
        {createCalibrateButton("y", props, sensorKey)}
        {createCalibrateButton("negY", props, sensorKey)}
        {accel0 && props.calibrating && <NumberBox value={accel0[1]} />}
      </Stack>
      <Stack spacing={2} direction="row">
        {createCalibrateButton("z", props, sensorKey)}
        {createCalibrateButton("negZ", props, sensorKey)}
        {accel0 && props.calibrating && <NumberBox value={accel0[2]} />}
      </Stack>
      {/*<hr />*/}
      {props.calibrating && (
        <Stack spacing={2} direction="row">
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              props.calibrate().then((values) => {
                // TODO ensure this snackbar works on this page, how to access?
                try {
                  toast.success("Saved successfully!");
                } catch (e) {
                  console.warn("error with calibrate button toast");
                }
                // TODO: check the range of the sensor calibration and flag it if it's out of range
              });
            }}
            disabled={disableCal}
          >
            Store Calibration
          </Button>
          <Button
            variant={"contained"}
            color={"info"}
            onClick={() => {
              props.cancelCalibration();
            }}
          >
            Cancel Calibration
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

function CalibrationDebug(
  props: BuildProps & { device: BuildDevice; sensorKey: keyof CalibrationValues },
) {
  if (!props.device.lastCalibration) {
    return null;
  }
  const sensorKey = props.sensorKey;
  const sensorCalibration = props.device.lastCalibration.calibration[sensorKey];
  if (!sensorCalibration) {
    return null;
  }
  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        p: 2,
      }}
    >
      <MathBox value={sensorCalibration} dataKey="x" sensorKey={sensorKey} />
      <MathBox value={sensorCalibration} dataKey="y" sensorKey={sensorKey} />
      <MathBox value={sensorCalibration} dataKey="z" sensorKey={sensorKey} />
    </Stack>
  );
}

export function CalibrationPage(props: BuildProps & { device: BuildDevice }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {props.connected && !props.calibrating && (
        <Button variant={"contained"} onClick={props.calibrate}>
          {props.device.lastCalibration ? "Re-Calibrate" : "Calibrate"}
        </Button>
      )}
      {props.connected && !props.calibrating && props.device.lastCalibration?.calibration && (
        <Button
          sx={{ ml: 1 }}
          variant={"contained"}
          onClick={() => props.resendToDisc(props.device.lastCalibration?.calibration)}
        >
          Resend to Disc
        </Button>
      )}
      {props.connected && props.calibrating && createCalibrateButtons(props)}
    </>
  );
}

export function LastCalibration(props: BuildProps & { device: BuildDevice }) {
  const lastCalibration = props.device.lastCalibration;
  if (props.calibrating || !lastCalibration) {
    return null;
  }
  return (
    <>
      <Grid item mobile={4}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Title>Accel 0</Title>
          {createCalibrateButtons(
            {
              ...props,
              readings: lastCalibration.readings,
            },

            "accel0",
          )}
          <CalibrationDebug {...props} sensorKey={"accel0"} />
        </Paper>
      </Grid>
      <Grid item mobile={4}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Title>Gyroscope</Title>
          {createCalibrateButtons(
            {
              ...props,
              readings: lastCalibration.readings,
            },

            "gyro",
          )}
          <CalibrationDebug {...props} sensorKey={"gyro"} />
        </Paper>
      </Grid>
      <Grid item mobile={4}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Title>Magnetometer</Title>
          {createCalibrateButtons(
            {
              ...props,
              readings: lastCalibration.readings,
            },

            "mag",
          )}
          <CalibrationDebug {...props} sensorKey={"mag"} />
        </Paper>
      </Grid>
      <Grid item mobile={4}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Title>Accel 1</Title>
          {createCalibrateButtons(
            {
              ...props,
              readings: lastCalibration.readings,
            },

            "accel1",
          )}
          <CalibrationDebug {...props} sensorKey={"accel1"} />
        </Paper>
      </Grid>
      <Grid item mobile={4}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Title>Accel 2</Title>
          {createCalibrateButtons(
            {
              ...props,
              readings: lastCalibration.readings,
            },

            "accel2",
          )}
          <CalibrationDebug {...props} sensorKey={"accel2"} />
        </Paper>
      </Grid>
      <Grid item mobile={12}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            padding: "1rem",
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: "1rem" }}>
              Last Calibration
            </Typography>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {format(lastCalibration.calibrationTime, "MM/dd/yyyy HH:mm:ss") +
                (lastCalibration.softwareVersion
                  ? "  using version: " + lastCalibration.softwareVersion
                  : "")}
            </Typography>
          </Box>
          <Button variant="primary" onClick={() => window.scrollTo(0, 0)}>
            Scroll to top
          </Button>
        </Paper>
      </Grid>
    </>
  );
}
