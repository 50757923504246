import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import LogRocket from "logrocket";
import { distinctUntilChanged, Subject } from "rxjs";

export const eventQueue$ = new Subject<TrackEventPayload>();

export const startEventQueue = () => {
  return eventQueue$
    .pipe(
      distinctUntilChanged(
        (prev, curr) =>
          prev.eventName === curr.eventName ||
          JSON.stringify(prev.eventData) === JSON.stringify(curr.eventData),
      ),
    )
    .subscribe((payload) => {
      if (process.env.NODE_ENV === "production") {
        logEvent(getAnalytics(), payload.eventName, {
          ...payload.eventData,
          userId: payload.userId,
          client: "web",
          sessionUrl: payload.sessionUrl,
        });
      }
      console.debug(`%c${payload.eventName}`, "color: #2a81de; font-weight: bold;", {
        ...payload.eventData,
        userId: payload.userId,
        client: "web",
        sessionUrl: payload.sessionUrl,
      });
    });
};

export function trackEvent(eventName: string, eventData: Record<string, unknown> = {}) {
  LogRocket.getSessionURL((sessionUrl) => {
    eventQueue$.next({
      eventName,
      eventData,
      userId: getAuth().currentUser?.uid,
      sessionUrl,
    });
  });
}

export type TrackEventPayload<TEventData = Record<string, unknown>> = {
  eventName: string;
  eventData: TEventData;
  userId?: string;
  sessionUrl?: string;
};
