import type { ThrowWarningStatus } from "@/components/consts";
import { DocumentData, Timestamp } from "firebase/firestore";
import { CoreMetrics } from "../firebase/converters/analysisSet";
import { CoreStats } from "./CoreStats";
import { LeaderboardEntry } from "./leaderboard";
import { Handedness } from "./UserSettings";

// default is "private"
export type ThrowVisibility = "public" | "private";

// HIGH_GRAVITY: This means the disc didn't have a good "lock" on which direction gravity was
// CLOSE_NET: This means the disc was too close to the net.  See ThrowSummary#netHitSeconds field for how close.
// HIGH_DELTA_V: This can show up when throwing rapid fire sometimes.
// NO_BACKSWING: This usually means there is an issue with the battery connection and the disc reset when hititng the net.
// LOW_SPEED: This means the disc was thrown too slowly.
// HIGH_SPEED: This reading is above what is humanly possible.
// RAPID_FIRE: This means the disc was thrown too quickly after the last throw.

export type WarningStatus =
  | "HIGH_GRAVITY"
  | "CLOSE_NET"
  | "HIGH_DELTA_V"
  | "NO_BACKSWING"
  | "LOW_SPEED"
  | "HIGH_SPEED"
  | "RAPID_FIRE"
  | "BRICK_WALL"
  | "CLIENT_CLOCK_IN_FUTURE"
  | "CLIENT_CLOCK_IN_PAST"
  | "DEVICE_DISABLED";

export interface FlightNumbers {
  speed: number;
  glide: number;
  turn: number;
  fade?: number;
  weight: number;
}

export enum AnalysisType {
  KIOSK = "kiosk",
  MANUAL = "manual",
}

export interface AnalysisSet extends DocumentData {
  ids: string[];
  createTime: Timestamp;
  title?: string;
  avg?: CoreStats;

  flightNumbers?: FlightNumbers;
  visibility?: ThrowVisibility;
  email?: string;
  type?: AnalysisType;
  tagIntersect?: string[];
  primaryTypeIntersect?: string;
  secondaryTypeIntersect?: string;

  leaderboardEntry?: LeaderboardEntry;
}

export type Identifiable = { id: string };

export type CoreStatsAndId = CoreStats & Identifiable & { handedness?: Handedness };

export interface ThrowSummary extends CoreMetrics {
  deviceId: string;
  estimatedDisc?: string;
  estimatedFlightNumbers?: FlightNumbers;
  gravityCorrectDegrees?: number;
  // This was added to each throw Feb 2024
  handedness?: Handedness & string;
  hardwareVersion?: number;
  hasFlight?: boolean;
  hidden?: string;
  hasNotes?: boolean;
  id: string;
  releaseIndex?: number;
  secondaryType?: string;
  temp: number;
  totalRotateZ?: number;
  uploadTime: Timestamp;
  videoOffsetMillis?: number;
  videoPath?: string;
  visibility?: ThrowVisibility;
  netHitSeconds?: number | null;
  bearing?: number | null;
  warningStatus?: ThrowWarningStatus | null;
  loading?: boolean;
  videoMetadata?: VideoMetadata;
}

export type ThrowUploadMetadata = {
  deviceId: string; // mac address
  deviceUid?: string; // unique id of the device
  deviceBeginTransfer: string; // millis since epoch as string
  bluetoothMillis: string; // millis since epoch as string
  uploadMillis: string; // millis since epoch as string
  deviceSoftware?: string; // version string, e.g. "0.16.11"
  client?: string; // "web" or "ios"
  userId?: string;
};

export type VideoMetadata = {
  coordinator: CameraMetadata;
};

export type CameraMetadata = {
  aspectRatio: number;
  backswingDuration: number;
  frameRate: number;
  fullFilePath: string;
  storageSize: number;
  thumbnailPath: string;
  type: string;
};
