import { trackEvent } from "@/utils/logging";
import { yupResolver } from "@hookform/resolvers/yup";
import { KeyboardReturn } from "@mui/icons-material";
import { Button, Paper, Stack } from "@mui/material";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { createStockShot } from "../../firebase/stock-shots/createStockShot";
import { updateThrowSummary } from "../../firebase/throwSummary/updateThrowSummary";
import { ThrowSummary } from "../../model/throwSummary";
import { Handedness } from "../../model/UserSettings";
import { averageThrowMetrics } from "../../utils/math";
import { ThrowSessionStatus, useThrowSession } from "../context/ThrowSessionProvider";
import { containerVariants } from "../routes/variants";
import Stepper from "../Stepper";
import { Black, Medium } from "../Text";
import CompleteStockShotStep from "./complete";
import GettingStartedStep from "./getting-started";
import ReviewThrowsStep from "./review";
import ThrowStep from "./throw";
import { toast } from "sonner";

export type StockShotsProps = {
  userId: string;
};

enum StockShotStep {
  GettingStarted = 1,
  Throw = 2,
  Review = 3,
  Complete = 4,
}

const stockShotInfoSchema = yup.object().shape({
  throwStyle: yup.string().required(),
  shotType: yup.string().required(),
  handedness: yup.mixed<Handedness>().oneOf([Handedness.LEFT, Handedness.RIGHT]).required(),
});

type StockShotFields = yup.InferType<typeof stockShotInfoSchema>;

function StockShots(props: StockShotsProps) {
  const { userId } = props;
  const [activeStep, setActiveStep] = useState(StockShotStep.GettingStarted);
  const [stagedThrows, setStagedThrows] = useState<ThrowSummary[]>([]);
  const { t } = useTranslation();
  const { status, minThrows, endSession } = useThrowSession();
  const navigate = useNavigate();

  const stageThrow = useCallback((throwSummary?: ThrowSummary) => {
    if (throwSummary) {
      setStagedThrows((throws) => [throwSummary, ...throws]);
    }
  }, []);

  const unstageThrow = useCallback((throwSummary?: ThrowSummary) => {
    if (throwSummary) {
      setStagedThrows((throws) => throws.filter((t) => t.id !== throwSummary.id));
    }
  }, []);

  const handleIncrementStep = useCallback(() => {
    setActiveStep((step) => step + 1);
  }, [setActiveStep]);

  const methods = useForm<StockShotFields>({
    resolver: yupResolver(stockShotInfoSchema),
  });

  const onSubmit = async (fields: StockShotFields) => {
    const throwIds = stagedThrows.map((t) => t.id);
    const stockShot = {
      ...fields,
      throwIds,
      avg: averageThrowMetrics(stagedThrows),
    };

    try {
      await createStockShot(userId, stockShot);
      trackEvent("stock_shot_created", {
        userId,
        selectedCount: stagedThrows.length,
        totalCount: stagedThrows.length,
      });
      // Update all the throws to mirror the handedness of the stock shot.
      throwIds.forEach(async (id) => {
        await updateThrowSummary(userId, id, { handedness: stockShot.handedness });
      });
    } catch (e) {
      toast.error("Failed to create stock shot.");
      return;
    }
    toast.success("Stock shot created.");
    navigate("/stock-shots");
  };

  const steps = useMemo(
    () => [
      {
        label: t("gettingStarted"),
        disabled: !methods.formState.isValid,
        onClick: setActiveStep,
        onSubmit: handleIncrementStep,
      },
      {
        label: t("throw"),
        disabled: status !== ThrowSessionStatus.Fulfilled,
        onClick: setActiveStep,
        onSubmit: () => {
          handleIncrementStep();
          endSession();
        },
      },
      {
        label: t("review"),
        disabled: stagedThrows.length < (minThrows ?? 0),
        onClick: setActiveStep,
        onSubmit: handleIncrementStep,
      },
      {
        label: t("complete"),
        disabled: stagedThrows.length < (minThrows ?? 0),
        onClick: setActiveStep,
      },
    ],
    [
      status,
      stagedThrows,
      minThrows,
      setActiveStep,
      handleIncrementStep,
      endSession,
      t,
      methods.formState,
    ],
  );

  return (
    <FormProvider {...methods}>
      <Paper
        component={motion.div}
        variants={containerVariants}
        sx={{
          width: "100%",
          minHeight: "512px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack
            gap={{ mobile: 2, tablet: 4 }}
            sx={{
              width: "100%",
              px: { mobile: 2, tablet: 2, laptop: 4 },
              pt: { mobile: 2, tablet: 1.5, laptop: 2.5 },
              pb: { mobile: 2, tablet: 4, laptop: 6 },
              overflowX: "hidden",
              height: "100%",
            }}
          >
            <LayoutGroup>
              <AnimatePresence mode="wait">
                {activeStep === StockShotStep.GettingStarted ? (
                  <Stack gap={{ mobile: 2, tablet: 4 }} sx={{ width: "100%" }}>
                    <Stack gap={4}>
                      <Stack direction="row" justifyContent="space-between">
                        <Black size={{ mobile: 20, tablet: 28 }} color="grey.700" spacing="dense">
                          {t("gettingStarted")}
                        </Black>
                        <Button variant="secondary" component={Link} to="/stock-shots">
                          <KeyboardReturn fontSize="small" />
                          {t("backToStockShots")}
                        </Button>
                      </Stack>
                      <GettingStartedStep />
                    </Stack>
                  </Stack>
                ) : null}
                {activeStep === StockShotStep.Throw ? (
                  <Stack gap={{ mobile: 2, tablet: 4 }} sx={{ width: "100%" }}>
                    <Stack gap={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Black size={{ mobile: 20, tablet: 28 }} color="grey.700" spacing="dense">
                          {t("throw")}
                        </Black>
                        <Button variant="secondary" component={Link} to="/stock-shots">
                          <KeyboardReturn fontSize="small" />
                          {t("backToStockShots")}
                        </Button>
                      </Stack>
                      <Medium size={{ mobile: 14, tablet: 16 }} color="grey.600">
                        {t("throwProfile.instructions.throw")}
                      </Medium>
                    </Stack>
                    <ThrowStep userId={userId} />
                  </Stack>
                ) : null}
                {activeStep === StockShotStep.Review ? (
                  <Stack gap={{ mobile: 2, tablet: 4 }} sx={{ width: "100%" }}>
                    <Stack gap={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Black size={{ mobile: 20, tablet: 28 }} color="grey.700" spacing="dense">
                          {t("review")}
                        </Black>
                        <Button variant="secondary" component={Link} to="/stock-shots">
                          <KeyboardReturn fontSize="small" />
                          {t("backToStockShots")}
                        </Button>
                      </Stack>
                      <Medium size={{ mobile: 14, tablet: 16 }} color="grey.600">
                        {t("throwProfile.instructions.review")}
                      </Medium>
                    </Stack>
                    <ReviewThrowsStep
                      stageThrow={stageThrow}
                      unstageThrow={unstageThrow}
                      stagedThrows={stagedThrows}
                    />
                  </Stack>
                ) : null}
                {activeStep === StockShotStep.Complete ? (
                  <Stack gap={{ mobile: 2, tablet: 4 }} sx={{ width: "100%" }}>
                    <Stack gap={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Black size={{ mobile: 20, tablet: 28 }} color="grey.700" spacing="dense">
                          {t("complete")}
                        </Black>
                        <Button variant="secondary" component={Link} to="/stock-shots">
                          <KeyboardReturn fontSize="small" />
                          {t("backToStockShots")}
                        </Button>
                      </Stack>
                      <Medium size={{ mobile: 14, tablet: 16 }} color="grey.600">
                        {t("throwProfile.instructions.complete")}
                      </Medium>
                    </Stack>
                    <CompleteStockShotStep throws={stagedThrows} />
                  </Stack>
                ) : null}
              </AnimatePresence>
            </LayoutGroup>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={4}
            sx={{
              p: 4,
              borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
              backgroundColor: "grey.50",
              borderBottomRightRadius: 4,
              borderBottomLeftRadius: 4,
              height: "fit-content",
            }}
          >
            <Stepper steps={steps} activeStep={activeStep} orientation="horizontal" />
            {activeStep === steps.length ? (
              <Button
                // Weird crappy hack to prevent form submission when  hitting "Next"
                // and transitioning to the final step.
                key={activeStep}
                // Weird crappy hack to prevent form submission when hitting "Next"
                // and transitioning to the final step.
                ref={(el) => {
                  if (el) {
                    setTimeout(() => {
                      el.type = "submit";
                    }, 0);
                  }
                }}
                variant="primary"
                disabled={steps.at(-1)?.disabled}
                sx={{ height: "fit-content" }}
              >
                {t("complete")}
              </Button>
            ) : (
              <Button
                // Weird crappy hack to prevent form submission when hitting "Next"
                // and transitioning to the final step.
                type="button"
                key={activeStep}
                onClick={steps[activeStep - 1].onSubmit}
                disabled={steps[activeStep - 1].disabled}
                variant="primary"
                sx={{ height: "fit-content" }}
              >
                {t("next")}
              </Button>
            )}
          </Stack>
        </form>
      </Paper>
    </FormProvider>
  );
}

export default StockShots;
