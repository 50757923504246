import { trackEvent } from "@/utils/logging";
import { IosShareOutlined } from "@mui/icons-material";
import { Button, Popover, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useIds from "../hooks/useIds";
import { useUser } from "../hooks/useUser";
import { shareAnalysisSet, storeSummary } from "../summaryUtils";
import { toast } from "sonner";

function ShareButton(props: { latestThrowId?: string; analysisSetId?: string }) {
  const { latestThrowId } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [working, setWorking] = useState(false);
  const { throwId, throwSetId } = useIds();
  const [{ trueUserId }] = useUser();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "share-popover" : undefined;
  return (
    <div>
      <Button
        aria-describedby={id}
        size="small"
        variant="link"
        onClick={handleClick}
        startIcon={<IosShareOutlined />}
        sx={{
          p: 1,
          textTransform: "uppercase",
          fontSize: "0.9rem",
        }}
      >
        {t("share")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "20px" }}>
          <Typography variant="h6">
            {t(throwSetId ? "shareThrowSetDialogTitle" : "shareThrowDialogTitle")}
          </Typography>
          <p>{t(throwSetId ? "sharedThrowSetLinkWarning" : "sharedThrowLinkWarning")}</p>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
            <Button
              disabled={working}
              variant="contained"
              color="primary"
              onClick={async () => {
                setWorking(true);
                let url = "";

                if (trueUserId && latestThrowId) {
                  // do actual firebase setting of visibility
                  await storeSummary(trueUserId, latestThrowId, { visibility: "public" });
                  trackEvent("share_throw_link", { throwId: latestThrowId });
                  url = `${window.location.origin}/s/throws/${trueUserId}/${latestThrowId}`;
                }

                if (trueUserId && throwId) {
                  // do actual firebase setting of visibility
                  await storeSummary(trueUserId, throwId, { visibility: "public" });
                  trackEvent("share_throw_link", { throwId });
                  url = `${window.location.origin}/s/throws/${trueUserId}/${throwId}`;
                }

                if (trueUserId && throwSetId) {
                  await shareAnalysisSet(trueUserId, throwSetId);
                  trackEvent("share_throw_set", { throwSetId });
                  url = `${window.location.origin}/s/throws/sets/${trueUserId}/${throwSetId}`;
                }
                const directCopy = copy(url, {
                  format: "text/plain",
                  message: t("instructions.selectAllClipboard"),
                });
                if (directCopy) {
                  toast.info(t("messages.copiedClipboard"));
                }
                setWorking(false);
                handleClose();
              }}
            >
              Share + Copy Link
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default ShareButton;
