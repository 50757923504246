import useRoleCheck from "@/hooks/useRoleCheck";
import { User, getAuth } from "firebase/auth";
import React, { createContext, useContext, useState, type ReactNode } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useMatches, useParams } from "react-router-dom";
import { FeatureFlag } from "../../featureFlags";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import UserSettings from "../../model/UserSettings";
import { getUserDoc } from "../../summaryUtils";
import { UserRoles } from "../user/types";

type GlobalContextType = {
  user?: User | null;
  userSettings?: UserSettings;
  userId: string;
  externalUserId?: string;
  userLoading: boolean;
  userRolesLoading: boolean;
  userRoles: UserRoles;
  isLiveAccount: boolean;
  isPremiumStore: boolean;
  isStore: boolean;
  isBetaUser: boolean;
  isAdmin: boolean;
  isManager: boolean;
  isManufacturing: boolean;
  isProUser: boolean;
  isInspectRoute: boolean;
  isShareRoute: boolean;
  isDeveloper: boolean;
  isSupport: boolean;
  isDebugThrows: boolean;
  isPlayUser: boolean;
  isUnityDeveloper: boolean;
  prefersMetric: boolean;
  latestThrowId: string;
  setLatestThrowId: (id: string) => void;
  featureFlags: Record<FeatureFlag, boolean>;
};

export const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const useGlobal = (): GlobalContextType => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
};

type GlobalProviderProps = {
  children: ReactNode;
};

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const matches = useMatches();
  const isInspectRoute = matches.some((match) => match.pathname.startsWith("/admin/inspect"));
  const isShareRoute = matches.some((match) => match.pathname.startsWith("/s"));
  const [latestThrowId, setLatestThrowId] = useState<string>("");

  const [user, userLoading] = useAuthState(getAuth());
  const userId = user?.uid ?? "unknown";
  const [userSettings] = useDocumentData(user && !userLoading ? getUserDoc(userId) : null);
  const { userId: externalUserId } = useParams();

  const featureFlags = useFeatureFlags(user);
  const {
    userRoles,
    userRolesLoading,
    isLiveAccount,
    isStore,
    isPremiumStore,
    isAdmin,
    isManager,
    isManufacturing,
    isBetaUser,
    isDebugThrows,
    isDeveloper,
    isSupport,
    isUnityDeveloper,
    isProUser,
  } = useRoleCheck(user, userLoading);

  return (
    <GlobalContext.Provider
      value={{
        user,
        userId,
        externalUserId,
        userSettings,
        userRoles,
        userLoading,
        userRolesLoading,
        isLiveAccount,
        isPremiumStore,
        isStore,
        isBetaUser,
        isAdmin,
        isManager,
        isManufacturing,
        isInspectRoute,
        isShareRoute,
        isDeveloper,
        isSupport,
        isProUser,
        isUnityDeveloper,
        isDebugThrows,
        prefersMetric:
          featureFlags.metric_enabled && userSettings?.preferredMeasurementSystem === "metric",
        featureFlags,
        latestThrowId,
        setLatestThrowId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
